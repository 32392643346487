import React, { Component } from 'react';
import {Parallax} from 'react-parallax';
import Security from './Images/Confidentiality.jpg';
import NavBar2 from './../../components/Navbar/Navbar2';

class Confidential extends Component {
    render() { 
        return (
            <div>
                <NavBar2/>
<Parallax bgImage={Security} blur={2} >
    <div style={{height:'380px'}} >
    <h1 style={{color:'#F8F9FA',textAlign:'center',
            fontFamily:'Poppins,sans-serif',fontWeight:'750',fontSize:'3.5em',marginTop:'20%'}}>
                Politique de confidentialité<br/> des Passagers
                  </h1>    
                  </div>
    </Parallax>                
            </div>
        );
    }
}
 
export default Confidential;