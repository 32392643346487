import React, { Component } from 'react';
import {Col,Row,Container} from 'react-bootstrap';
import './IconText.css';
 
class IconText extends Component {
    render() { 
        return (
            <div>
                <div className={'TextIcons'}>

                <div className='Icon'>
                <img src={this.props.img} alt='first icon' height={this.props.height} width={this.props.width} />
                </div>
                <div className='text' >
        <h4 style={{color:'#f7a83f',textAlign:'center'}} className='TitleIcons h4-responsive' >{this.props.title}</h4>
        <h7 >{this.props.text}</h7>
        <h7 style={{color:'white'}}>{this.props.text2}</h7>
                </div>
            </div>

            </div>

            
        );
    }
}
 
export default IconText ;