import React, {Component} from 'react';
import FonctionnementPills from "./FonctionnementPills";
import NavBar2 from "../../components/Navbar/Navbar2";
import Footter from "../../components/Footer/Footter";

class Fonctionnement extends Component {
    render() {
        return (
            <div>
                    <NavBar2/>

                <div style={{width:'95%',marginRight:'2.5%',marginLeft:'2.5%',marginTop:'90px'}} >
                    <h1 style={{fontWeight:'bold',color:'#82BB25'}} >Comment utiliser l'application :</h1>
                <p>Notre technologie permet de mettre en relation les professionels du transport et de<br/> la restauration avec leurs consommateurs.</p>
                <p>Nous mettons tout notre coeur afin d'améliorer les différents services que l'on peut vous<br/> proposer à travers notre application.</p>
                <p>Vous observerez sur cette page le fonctionnement de nos services étape par étape:</p>
                </div>
                <div style={{marginTop:'3%'}}>
                    <FonctionnementPills/>
                </div>
                <div style={{marginTop:'5%'}}>
                    <Footter/>
                </div>
            </div>
        );
    }
}

export default Fonctionnement;