import React, {Component} from 'react';
import NavBar2 from "../../components/Navbar/Navbar2";
import CarrierPills from "./CarrierPills/CarrierPills";
import Footter from "../../components/Footer/Footter";
import ArrowTop from "../../components/ArrowTop/ArrowTop";

function Carriere ()  {
        return (
            <div>
                <NavBar2/>
                <div style={{marginTop:'60px'}}>
                    <CarrierPills/>
                </div>
                <ArrowTop/>
                <div style={{marginTop:'7%'}}>
                    <Footter/>
                </div>

            </div>
        );

}

export default Carriere;