import React, {Component} from 'react';

class IconText2 extends Component {
    render() {
        return (
            <div>
                <div className={'TextIcons2'}>

                <div className='Icon'>
                <img src={this.props.img}  height={this.props.height} width={this.props.width} />
                </div>
                <div className='text' >
        <h4 style={{color:'white',textAlign:'center'}} className='TitleIcons h4-responsive' >{this.props.title}</h4>
        <h7 style={{color:'white',textAlign:'left'}}>{this.props.text2}</h7>
                </div>
            </div>

            </div>
        );
    }
}

export default IconText2;