import React,{useState} from 'react';
import { Modal,Button } from 'react-bootstrap';
import UiCard from "../UiCard/UiCard";
import {Link} from 'react-router-dom';
import './ModalChoice.css';
import avatar from './images/user-profile.svg';

export function ModalChoiceLogin() {
    const [lgShow, setLgShow] = useState(false);
  
    return (
      <>
        <span style={{cursor:'pointer'}} style={{backgroundColor:'transparent',color:'white'}} onClick={() => setLgShow(true)}>
        <img  width="25" height="25" src={avatar} alt="utilisateur Icône gratuit" title="utilisateur Icône gratuit" class="loaded"/>{" "}
         <span style={{color:'white',fontWeight:'bold'}} >Connextion</span>
        </span>
       
        <Modal
        style={{zIndex:'9999'}}
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Connectez à votre compte Yalla
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className='container-fluid'>
              <div className='row MyRow align-items-center ' >
<div className='col Mycol col-lg-4 col-md-12-center col-sm-12 '>
  <Link to='/LoginTaxi' style={{textDecoration:'none',color:'black'}} >
  <UiCard Title='Compte Taxi' description='Se connecter à votre compte Taxi' image='https://images.unsplash.com/photo-1468689210283-44a1b8ba20ce?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80' />

  </Link>
</div>
<div className='col Mycol col-lg-4 toptop col-md-12 col-sm-12'>
  <Link to='/LoginPassager' style={{textDecoration:'none',color:'black'}}>
  <UiCard Title='Compte Client ' description='Se connecter à votre compte Passager' image='https://images.unsplash.com/photo-1532937660911-9565a830fa0f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80'/>
  </Link>

</div>
<div className='col Mycol col-lg-4 toptop col-md-12 col-sm-12'>
  <Link to='/LoginDelevry' style={{textDecoration:'none',color:'black'}}>
  <UiCard Title='Compte Livreur' description='Se connecter à votre compte livreur' image='https://images.unsplash.com/photo-1526367790999-0150786686a2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=751&q=80' />

  </Link>

</div>
              </div>
              </div>
        
          </Modal.Body>
        </Modal>
      </>
    );
  }
  
  