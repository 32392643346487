import React, { Component } from 'react';
import {Image} from 'react-bootstrap';
import Smile from '../images/smiley.jpg';
import Digital from '../images/Digita.jpg';
import Slide from 'react-reveal/Slide';
import './PagesPills.css';

 
class Prise extends Component {
    render() {

        return (
            <div className='plafond' style={{marginTop:'6%'}}>
              <div>
    <h1 style={{textAlign:'center'}} >Eviter les frustrations du quotidien durant votre service</h1>
    <p style={{textAlign:'center'}} >

    Vous n’êtes pas les seuls à être notés et des outils sont mis à votre disposition afin de vous minimiser les tracas de votre quotidien d’entrepreneur.</p>
</div>


           <div style={{marginTop:'10%'}} className="container">

<div className='row' >
<Slide left timeout={1000}>
<div className='col-12 col-md-6' >

                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={Smile} fluid/>


                        </div>
                           
                 </Slide>
                 <Slide right timeout={1000}>
        <div style={{marginTop:'12%'}}  className='col-12 col-md-6' >
          <div >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Un soucis d’équité ?</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Vous n’êtes pas seuls à être notés, Vous pouvez vous aussi noter vos clients afin de permettre de faire un suivi des plus récalcitrants. </p>
          </div>
          
        </div>
        </Slide>
           
</div>
             
      </div>
                      
    

      <div style={{marginTop:'10%'}}  className="container">



<div className='row flex-column-reverse flex-md-row  ' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6 ' style={{marginTop:'12%'}} >
 <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Ayez accès à vos reportings</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Observez les indicateurs de vos revenus pour améliorer votre gestion financière et afin d’améliorer votre activité.</p>
</div>
</Slide>
<Slide right timeout={1000} >
<div className='col-12 col-md-6 ' >
                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={Digital} fluid/>

</div>
</Slide>


</div>
      
     </div>
            </div>
        );
    }
}
 
export default Prise;