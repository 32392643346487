import React, { Component } from 'react';
import {Image} from 'react-bootstrap';
import Dashbord from '../images/dashbord.jpg';
import Transport from '../images/transport.jpg';
import Slide from 'react-reveal/Slide';
import './PagesPills.css';
import Button from '@material-ui/core/Button';
import { createMuiTheme,ThemeProvider } from '@material-ui/core/styles';

class Avantages extends Component {
    render() {
        const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#82BB25',
    },

  },
});
        return (
            <div className={'plafond'} style={{marginTop:'6%'}}>
           
<div>
    <h1 style={{textAlign:'center'}} >Plus et mieux vous conduisez plus vous gagnez !
</h1>
    <p style={{textAlign:'center'}} >
    Plus votre profil est proche du 5 étoiles et plus vos courses mensuelles sont importantes, plus la commission déjà basse sera abaissée.</p>
  
</div>

           <div style={{marginTop:'10%'}} className="container">

<div className='row' >
<Slide left timeout={1000}>
<div className=' col-12 col-md-6' >

                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={Dashbord} fluid/>


                        </div>
                           
                 </Slide>
                 <Slide right timeout={1000}>
        <div style={{marginTop:'10%'}}  className='col-12 col-md-6 ColParag ' >
          <div >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Complétez vos revenus !</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >En plus du service de courses qui vous est proposé,
vous pouvez participer au service de livraison Yalla afin d’augmenter vos revenus dans vos creux de service.</p>
          </div>
          <div style={{display:'flex',justifyContent:'center'}} >
 <ThemeProvider theme={theme}>
        <Button variant="contained" color="primary"  style={{color:'white',borderRadius:'1px' }} >Réevelation de sa commission</Button>
                              </ThemeProvider>     </div>
        </div>
        </Slide>
           
</div>
             
      </div>
                      
    

      <div style={{marginTop:'10%'}}  className="container">



<div className='row flex-column-reverse flex-md-row  ' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6 ColParag  ' style={{marginTop:'10%'}} >
 <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Complétez vos revenus !</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >
En plus du service de courses qui vous est proposé,
vous pouvez participer au service de livraison Yalla afin d’augmenter vos revenus dans vos creux de service.</p>
          <div style={{display:'flex',justifyContent:'center'}} >
 <ThemeProvider theme={theme}>
        <Button variant="contained" color="primary"  style={{color:'white',borderRadius:'1px' }}  >Revenus de livraison</Button>
                              </ThemeProvider>     </div>
</div>

</Slide>
<Slide right timeout={1000} >
<div className='col-12 col-md-6 ' >
                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={Transport} fluid/>

</div>
</Slide>


</div>
      
     </div>
                
            </div>
        );
    }
}
 
export default Avantages;