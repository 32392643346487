import React, {Component} from 'react';
import Slide from "react-reveal/Slide";
import {Image} from "react-bootstrap";
import serpentin from '../Images/Serpentin taxi.png';

class Course extends Component {
    render() {
        return (
            <div style={{marginTop:'4%'}}>

      <div  className="container-fluid">

<div className='row' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6' >
        <Image style={{marginLeft:'17%'}}  width={'70%'} src={serpentin}  fluid/>
</div>
</Slide>

<div className='col-12 col-md-6' >
          <div style={{marginTop:'5%'}}  >
          <h3 style={{textAlign:'left',fontFamily:'Lato, sans-serif',fontWeight:'bold',color:'#82BB25'}} >1.Le client se connecte sur son application</h3>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif',fontWeight:'bold'}} >Le client se connecte sur son compte personnel afin d'accéder aux différents services proposés par Yalla.</p>
              <h3 style={{textAlign:'left',fontFamily:'Lato, sans-serif',fontWeight:'bold',color:'#82BB25'}} >2.Le client choisit sa destination</h3>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif',fontWeight:'bold'}} >Il se verra proposer un tarif avec des options. Une fois la commande confirmée, il est mis en relation un chauffeur à proximité.</p>
              <h3 style={{textAlign:'left',fontFamily:'Lato, sans-serif',fontWeight:'bold',color:'#82BB25'}} >3.Le client est pris en charge</h3>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif',fontWeight:'bold'}} >Le chauffeur désigné se dirige vers le lieu de prise en charge vérifie le prénom de son client pour pouvoir exécuter l'itinéraire.</p>
              <h3 style={{textAlign:'left',fontFamily:'Lato, sans-serif',fontWeight:'bold',color:'#82BB25'}} >4.Le client arrive à destination</h3>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif',fontWeight:'bold'}} >A la fin de la course. le client et le chaffeur se notent mutuellement A charge du client s'il veut décider de laisser un pourboire ou non. </p>
          </div>

        </div>
      </div>


</div>
            </div>
        );
    }
}

export default Course;