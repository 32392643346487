import React, {Component} from 'react';
import SideNav from '../../components/SideNav/SideNav';
import SupportNav from './../../components/SideNav/SupportNav';
import Footter2 from './../../components/Footer/Footter2';
import AppStore from './images/Download_on_the_App_Store_Badge (2).svg';
import GoogleStore from './images/Google_Play_Store_badge_FR.svg';


class SupportPage extends Component {
    render() {
        return (
            <div style={{backgroundColor:'#F8F9FA'}} >
               

                <div  className={'SideNav'}>
                    <SupportNav/>
                   
                    <SideNav/>

<div>
    <h4 style={{display:'flex',justifyContent:'center',marginTop:'3%'}} >Maintenant disponible sur :</h4>
</div>
<div style={{display:'flex',justifyContent:'center',marginTop:'3%'}}>
<div className='GooglePlay' >
    <a href='https://play.google.com/' >
    <img width="150" height="50" style={{borderRadius:'7px'}} src={GoogleStore}/>
    </a>
</div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<div className='AppleStore' >
    <a href='https://www.apple.com/fr/ios/app-store/' >
    <img width="150" height="50" style={{borderRadius:'7px'}} src={AppStore}/>
    </a>
</div>
</div>

                    <div style={{marginTop:'5%'}} >
                    <Footter2/>
                    </div>
                    
                </div>
              

            </div>
        );
    }
}

export default SupportPage;