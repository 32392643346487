import React from 'react';
import './CarrierPills.css';
import conference from '../images/conference-room-768441_1920.jpg';
import {Parallax} from 'react-parallax';
import conferencee from '../images/conference-room-768441_1920.jpg';
import Workplace from '../images/workplace-1245776_1920 (1).jpg';
import Company from '../images/company-5064997_1920.jpg';
import office from '../images/office-1209640_1920 (1).jpg';
import Slide from "react-reveal/Slide";
import {Image} from "react-bootstrap";

function WorkYalla () {

        return (
            <div>
                <Parallax blur={2} style={{width:'100%'}} bgImage={'https://images.unsplash.com/photo-1570126646281-5ec88111777f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=725&q=80'} >

                    <div className={'MyParallax'} style={{height:'445px' , marginTop:'16%',marginLeft:'4%'}} >
                        <div className="container">
                            <div className={'DivTitle'} >
                                <h1 className={'AproposTitle'} style={{color:'white',textAlign:'center',
                                    fontFamily:'Poppins,sans-serif',fontWeight:'750',fontSize:'4.5em'}}>
                                    Trouvez votre bonheur<br/> en nous développant conjointement
                                </h1>

                            </div>

                        </div>
                    </div>
                </Parallax>



                <div style={{marginTop:'10%'}} className="container">

                    <div className='row' >
                        <Slide left timeout={1000}>
                            <div className='col-12 col-md-6' >

                                <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={Workplace} fluid/>

                            </div>

                        </Slide>
                        <Slide right timeout={1000}>
                            <div style={{marginTop:'8%'}}  className='col-12 col-md-6' >
                                <div >
                                    <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >L’union fait la force :</h2>
                                    <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Nous mettons un point d’honneur sur le travail d’équipe. En ce sens, le développement de notre entreprise se fera en intégrant des processus de travaux en communs car nous croyons au principe que toute réflexion est bonne à prendre.</p>
                                </div>

                            </div>
                        </Slide>

                    </div>

                </div>



                <div style={{marginTop:'10%'}}  className="container">



                    <div className='row flex-column-reverse flex-md-row  ' >
                        <Slide left timeout={1000} >
                            <div className='col-12 col-md-6 ' style={{marginTop:'10%'}} >
                                <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Venez comme vous êtes :</h2>
                                <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Le travail ne vous définit pas, c’est vous qui le définissez. La diversité est essentielle au bon développement d’une entreprise. Elle rejoint le travail en équipe afin de permettre la mise en éveil du plus grand nombre d’idées de toutes horizons.</p>
                            </div>
                        </Slide>
                        <Slide right timeout={1000} >
                            <div className='col-12 col-md-6 ' >
                                <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={Company} fluid/>

                            </div>
                        </Slide>


                    </div>

                </div>





                <div style={{marginTop:'10%'}}  className="container">

                    <div className='row' >
                        <Slide left timeout={1000} >
                            <div className='col-12 col-md-6' >
                                <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={office} fluid/>
                            </div>
                        </Slide>

                        <Slide right timeout={1000} >
                            <div className='col-12 col-md-6' >
                                <div style={{marginTop:'20%'}} >
                                    <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Venez vous épanouir :</h2>
                                    <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >La diversité de postes à prévoir pour le développement de nos activités fait que le portefeuille est grand et que les challenges, même autres que métiers, seront importants. Nous accentuons aussi le besoin d’une vie privée et d’un salaire décent car, sans eux, pas d’épanouissement et donc pas de personnel efficient.</p>
                                </div>

                            </div>
                        </Slide>
                    </div>


                </div>


            </div>
        );

}

export default WorkYalla;