import React from 'react';
import 'leaflet/dist/leaflet.css';
import './LeafletPages.css';
import {
    TileLayer,
    Circle,
    MapContainer,
    Popup,
    CircleMarker,
    Polygon

} from 'react-leaflet';


function LeafletTunis () {
    const center = [36.7949999,10.073237]

    {/**/}

    const fillBlueOptions = { fillColor: 'blue' }
    const redOptions = { color: 'red' }
    const limeOptions = { color: 'lime' }
    return (


        <div className={'LeafletMap'} >
            <MapContainer center={center} zoom={10} scrollWheelZoom={false}>
                <TileLayer
                    style={{color:'black !important'}}
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polygon positions={[[36.86994, 10.35948],
                                     [36.78494, 10.29167],
                                     [36.72123, 10.36975],
                                     [36.68517, 10.31756],
                                     [36.6637, 10.25577],
                                     [36.667, 10.17405],
                                     [36.67912, 10.11912],
                                     [36.74367, 10.11688],
                                     [36.75192, 10.09611],
                                     [36.80294, 10.05829],
                                     [36.8258, 10.02382],
                                     [36.8368, 10.0012],
                                     [36.86022, 9.97356],
                                     [36.93573, 9.96292],
                                     [36.95987, 10.0563],
                                     [36.93682, 10.0872],
                                     [36.91487, 10.10299],
                                     [36.91706, 10.11467],
                                     [36.9478, 10.11192],
                                     [36.9692, 10.08377],
                                     [37.00156, 10.19741],
                                     [36.91556, 10.33175]

                ]} color="blue" />
                <Circle center={center} pathOptions={fillBlueOptions} radius={200} />

            </MapContainer>
        </div>

    );

}

export default LeafletTunis;


