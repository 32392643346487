import React, {Component} from 'react';
import {Parallax} from "react-parallax";
import Happy from "./Image/happy.jpg";
import Navbar2 from "../../components/Navbar/Navbar2";
import './LocationPages.css';
import Slide from 'react-reveal/Slide'
import Footter from "../../components/Footer/Footter";
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
import {GrMapLocation} from 'react-icons/gr';
import LeafletTunis from "../../components/Leaflet/LeafletTunis";
import {ScrollingProvider, useScrollSection, Section} from 'react-scroll-section';
import GPS from './Image/gps.png';
import LeafletHammamet from "../../components/Leaflet/LeafletHammamet";
class LocationHammamet extends Component {

    render() {
        return (
            <div style={{backgroundColor:'#F7F7F7'}}>
                <div   >
                    <Navbar2/>


                    <Parallax   bgImage={Happy} >

                        <div className={'MyParallax2'} style={{ height: '600px' }} >
                            <div className={'TitleLocation'}  >
                                <Slide timeout={4000} left >
                                    <h1 className={'LocationTtitle'} >Nous sommes<br/> présents sur<br/> tout le territoire<br/> Hammamet
                                        <img
                                            className={'GpsImg'}
                                        width="60" height="60"
                                        src={GPS}/></h1>
                                </Slide>
                            </div>

                        </div>

                    </Parallax>


                    <div className={'TitleMap'} id={'MyMap'} style={{marginTop:'8%'}}  >

                        <Flip timeout={1500}  bottom >
                            <LeafletHammamet/>
                        </Flip>
                        <Zoom timeout={1500} top >
                            <h1 style={{textAlign:'center',fontFamily:'Poppins,sans-serif',color:'#82BB25',marginTop:'2%'}} >Hammamet <GrMapLocation/></h1>
                        </Zoom>
                        <h5 style={{textAlign:'center'}} >Nos locaux seronts bientôt  ouverts ...</h5>
                    </div>


                </div>
                <div className={'footerMap'} style={{marginTop:'15%'}} >
                    <Footter/>
                </div>

            </div>
        );
    }
}

export default LocationHammamet;