import React, { Component } from 'react';
import './ArrowTop.css';
import $ from 'jquery';
import UpArrow from './up-Arrow.png';
 
class ArrowTop extends Component {

    componentDidMount(){

$(document).ready(function(){

$(window).scroll(function(){
    if($(this).scrollTop()>40){
        $('#TopButton').fadeIn();
    }
    else{
        $('#TopButton').fadeOut();
    }
});

$("#TopButton").click(function(){
    $('html,body').animate({scrollTop:0},2000)
})
})

    };// fin componentDidMount

    render() { 
   
        return (
            <div >
                <img id={'TopButton'} width="40" height="40" src={UpArrow} alt="Up" title="Up" class="loaded"/>
            </div>
        );
    }
}
 
export default ArrowTop;