import React from 'react';



function Notfoundpage (){

return (

<div>

<h1>

Not Found

</h1>

</div>

)


}




export default Notfoundpage;