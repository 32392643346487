import React, { Component } from 'react';
import './Home.css';
import NavBar2 from '../../components/Navbar/Navbar2';
import Carousel2 from '../../components/carousel/carousel2';
import IconText from '../../components/IconText/Icontext';
import communication from './icons/communication.svg';
import discount from './icons/discount.svg';
import userinterface from './icons/user-interface.svg';
import ElasCarou from '../../components/eslasticCarousel/ElasCarou';
import table from './icons/dining-table.svg';
import burger from './icons/fast-food.svg';
import license from './icons/driver-license.svg';
import Footter from '../../components/Footer/Footter';
import ArrowTop from '../../components/ArrowTop/ArrowTop';
import Bounce from 'react-reveal/Bounce';


function HomePage () {



        return (
            <div className={'YallaHome'} >
            <NavBar2/>

                    <div className={'carsouel'} >
                        <Carousel2/>
                    </div>
                
            

              <Bounce timeout={2000} right cascade >
                <div className="IconText">
                    <div className='IconText1' >
                    <IconText width={'100%'} height={200} img={communication} title={'Tarification basse et constante'}  />
                    <p style={{textAlign:'center'}} >Des services Abordables et récompensés<br/>avec des frais défiants toute concurrences</p>
                    </div>
                    <div className='IconText2' >
                    <IconText width={'100%'}  height={200} img={userinterface}  title={'Acessibilité 14/7'}   />
                    <p style={{textAlign:'center'}} >Chaque utilisation utilisable dans touts<br/> les services proposés par Yalla <b></b> </p>
                    </div>
                    <div className='IconText3' >
                    <IconText width={'100%'}  height={200} img={discount} title={'Fidélité Récomponsée'}  />
                    <p style={{textAlign:'center'}} >Un service disponible dans de nombreuses<br/> villes à n'mporte quelle heure </p>
                    </div>
                </div>
                
              </Bounce>
              <Bounce timeout={2000} top>
             
                <div style={{backgroundColor:'#82bb25'}}>
                <ElasCarou/>
                </div>
              </Bounce>

                <div>



<div style={{marginTop:'6%',marginBottom:'6%'}} className="IconText">

  
                  <Bounce timeout={2000} left >
                    <div className={'TextIcon4'}>
                    <IconText width={'100%'} height={200} img={license} title={'Des chauffeurs vérifiés et satisfait'}  />
                    <p style={{textAlign:'center'}} >Les partenaires sont récompensés de<br/> leur utilisation du services avec des<br/> avantages non négligables </p>
                    </div>



                     <div className={'TextIcon5'} >
                    <IconText width={'100%'} height={200} img={burger}  title={'Redéfinissez le shopping'}   />
                    <p style={{textAlign:'center'}} >Soutenez vos commerçants locaux en<br/> vous faisant livrer n'importe quel type de<br/> biens par les commerces de proximité </p>

                    </div>




                   
                    <div className={'TextIcon6'} >
                    <IconText width={'100%'}  height={200} img={table} title={'Léguez vos corvées'}  />
                    <p style={{textAlign:'center'}} >Evitez de perdre votre temps en vous<br/> déplaçant ou en appelant les commer-<br/>çants pour répondre à vos besoins </p>
                    </div>
                    </Bounce>
                </div>


               

                </div>
               
                <ArrowTop/>
                
                <Footter/>
                
            </div>
        );

}
 
export default HomePage;