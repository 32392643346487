import React, {Component} from 'react';
import Slide from "react-reveal/Slide";
import {Image} from "react-bootstrap";
import serpentin from '../Images/Serpentin order.png';

class Commande extends Component {
    render() {
        return (
            <div style={{marginTop:'4%'}}>

      <div  className="container-fluid">

<div className='row' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6' >
        <Image style={{marginLeft:'17%'}}  width={'70%'} src={serpentin}  fluid/>
</div>
</Slide>

<div className='col-12 col-md-6' >
          <div style={{marginTop:'5%'}}  >
          <h3 style={{textAlign:'left',fontFamily:'Lato, sans-serif',fontWeight:'bold',color:'#82BB25'}} >1.Le client se connecte sur son application</h3>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif',fontWeight:'bold'}} >Le client se connecte sur son compte personnel afin d'accéder aux différents services proposés par Yalla.</p>
              <h3 style={{textAlign:'left',fontFamily:'Lato, sans-serif',fontWeight:'bold',color:'#82BB25'}} >2.Le client choisit son restaurant</h3>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif',fontWeight:'bold'}} >Il se verra proposer des plats avec suppléments. Une fois la commande confirmée, il pourra suivre l'avancement de préparation de son repas.</p>
              <h3 style={{textAlign:'left',fontFamily:'Lato, sans-serif',fontWeight:'bold',color:'#82BB25'}} >3.Suivi de l'avancement</h3>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif',fontWeight:'bold'}} >Yalla fait en sorte de tenir au courant le clint du statut de sa commande : Validée, En cours de préparation, Prise en charge par le livreuret enfin Livrée.</p>
              <h3 style={{textAlign:'left',fontFamily:'Lato, sans-serif',fontWeight:'bold',color:'#82BB25'}} >4.La livraison est en cours</h3>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif',fontWeight:'bold'}} >Une fois la commande prête, le client est tenu au courant de la position du livreur en direct et sera contacté pour récupérer une fois à destination. </p>
          </div>

        </div>
      </div>


</div>
            </div>
        );
    }
}

export default Commande;