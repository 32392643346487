import React, {Component} from 'react';
import './TeamSlide.css';

class TeamSlide extends Component {
    render() {
        return (
            <div>
                 {/* Team */}
      <section id="team" className="pb-5">
        <div className="container">
          <h5 className="section-title h1">L’Équipe Yalla</h5>
          <div className="row">
            {/* Team member */}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="image-flip">
                <div className="mainflip flip-0">
                  <div className="frontside">
                    <div className="card">
                      <div className="card-body text-center">
                        <p><img className=" img-fluid" src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_01.png" alt="card image" /></p>
                        <h4 className="card-title">Sunlimetech</h4>
                        <p className="card-text">This is basic card with image on top, title, description and button.</p>
                        <a href="https://www.fiverr.com/share/qb8D02" className="btn btn-primary btn-sm"><i className="fa fa-plus" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">


                    <div className="card">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Sunlimetech</h4>
                        <p className="card-text">This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-skype" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-google" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
            {/* ./Team member */}
            {/* Team member */}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="image-flip" ontouchstart="this.classList.toggle('hover');">
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card">
                      <div className="card-body text-center">
                        <p><img className=" img-fluid" src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_02.png" alt="card image" /></p>
                        <h4 className="card-title">Sunlimetech</h4>
                        <p className="card-text">This is basic card with image on top, title, description and button.</p>
                        <a href="https://www.fiverr.com/share/qb8D02" className="btn btn-primary btn-sm"><i className="fa fa-plus" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Sunlimetech</h4>
                        <p className="card-text">This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-skype" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-google" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ./Team member */}
            {/* Team member */}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="image-flip" ontouchstart="this.classList.toggle('hover');">
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card">
                      <div className="card-body text-center">
                        <p><img className=" img-fluid" src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_03.png" alt="card image" /></p>
                        <h4 className="card-title">Sunlimetech</h4>
                        <p className="card-text">This is basic card with image on top, title, description and button.</p>
                        <a href="https://www.fiverr.com/share/qb8D02" className="btn btn-primary btn-sm"><i className="fa fa-plus" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Sunlimetech</h4>
                        <p className="card-text">This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-skype" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-google" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ./Team member */}
            {/* Team member */}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="image-flip" ontouchstart="this.classList.toggle('hover');">
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card">
                      <div className="card-body text-center">
                        <p><img className=" img-fluid" src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_04.jpg" alt="card image" /></p>
                        <h4 className="card-title">Sunlimetech</h4>
                        <p className="card-text">This is basic card with image on top, title, description and button.</p>
                        <a href="https://www.fiverr.com/share/qb8D02" className="btn btn-primary btn-sm"><i className="fa fa-plus" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Sunlimetech</h4>
                        <p className="card-text">This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-skype" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-google" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ./Team member */}
            {/* Team member */}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="image-flip" ontouchstart="this.classList.toggle('hover');">
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card">
                      <div className="card-body text-center">
                        <p><img className=" img-fluid" src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_05.png" alt="card image" /></p>
                        <h4 className="card-title">Sunlimetech</h4>
                        <p className="card-text">This is basic card with image on top, title, description and button.</p>
                        <a href="https://www.fiverr.com/share/qb8D02" className="btn btn-primary btn-sm"><i className="fa fa-plus" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Sunlimetech</h4>
                        <p className="card-text">This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-skype" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-google" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ./Team member */}
            {/* Team member */}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="image-flip" ontouchstart="this.classList.toggle('hover');">
                <div className="mainflip">
                  <div className="frontside">
                    <div className="card">
                      <div className="card-body text-center">
                        <p><img className=" img-fluid" src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_06.jpg" alt="card image" /></p>
                        <h4 className="card-title">Sunlimetech</h4>
                        <p className="card-text">This is basic card with image on top, title, description and button.</p>
                        <a href="https://www.fiverr.com/share/qb8D02" className="btn btn-primary btn-sm"><i className="fa fa-plus" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="backside">
                    <div className="card">
                      <div className="card-body text-center mt-4">
                        <h4 className="card-title">Sunlimetech</h4>
                        <p className="card-text">This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-skype" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="social-icon text-xs-center" target="_blank" href="https://www.fiverr.com/share/qb8D02">
                              <i className="fa fa-google" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ./Team member */}
          </div>
        </div>
      </section>
      {/* Team */}
            </div>
        );
    }
}

export default TeamSlide;