import React from 'react';
import './SideNav.css';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Link} from "react-router-dom";
import SupportNav from './SupportNav';
import Street from './Images/client.jpg';
import Chauffeur from './Images/Chauffeur.jpg';
import Restaurant from './Images/Restaurant.jpg';
import {Parallax} from 'react-parallax';
import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade';
import ControlledTreeView from '../TreeView/TreeView';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

export default function VerticalTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div style={{backgroundColor:'#F8F9FA'}} >

<TabPanel style={{width:'100%',backgroundColor:'#F8F9FA'}} value={value} index={0}>
    <Fade>
    <Parallax bgImage={Street} >
                        <div style={{height:'190px'}} >
                            <h1 style={{marginTop:'10%',marginLeft:'17.5%',color:'white',
            fontFamily:'Poppins,sans-serif',fontWeight:'750',fontSize:'3.5em'}} >
                Un soucis dans notre service ?</h1>

                        </div>

                    </Parallax>
    </Fade>

</TabPanel>

<TabPanel style={{width:'100%'}} value={value} index={1}>
    <Fade>
    <Parallax bgImage={Chauffeur} >
                        <div style={{height:'190px'}} >
                            <h1 style={{marginTop:'10%',marginLeft:'17.5%',color:'white',
            fontFamily:'Poppins,sans-serif',fontWeight:'750',fontSize:'3.5em'}} >
                Un soucis dans notre service ?</h1>

                        </div>

                    </Parallax>
    </Fade>

</TabPanel>

<TabPanel style={{width:'100%'}} value={value} index={2}>
    <Fade>
    <Parallax bgImage={Restaurant} >
                        <div style={{height:'190px'}} >
                            <h1 style={{marginTop:'10%',marginLeft:'17.5%',color:'white',
            fontFamily:'Poppins,sans-serif',fontWeight:'750',fontSize:'3.5em'}} >
                Un soucis dans notre service ?</h1>
                        </div>

                    </Parallax>
    </Fade>

</TabPanel>


<div className={classes.root} style={{marginTop:'4%',backgroundColor:'#F8F9FA'}} >
                       


            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Clients" {...a11yProps(0)} />
                <Tab label="Chauffeurs & livreurs" {...a11yProps(1)} />
                <Tab label="Commerçants & restaurants " {...a11yProps(2)} />
              
                
            </Tabs>
            <TabPanel style={{width:'100%'}} value={value} index={0}>
                <div className={'TopicsPages'} style={{width:'100%',}}>
                    <ControlledTreeView un={'Problème liés à une course ou une commande'}
                                        unun={'Compte et moyen de paiement'}
                                        undeux={'Plus'}
                                        untrois={'Guide de Yalla'}
                                        uncinq={'Fidélité'} 
                                        unsix={"S'inscrire"}
                                        unsept={'Accessibilité'}
                                         />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    

                </div>
            </TabPanel>
            <TabPanel style={{width:'100%'}} value={value} index={1}>
                <div className={'TopicsPages'} style={{width:'100%',}}>
                <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                   
                </div>
            </TabPanel>
            <TabPanel style={{width:'100%'}} value={value} index={2}>
                <div className={'TopicsPages'} style={{width:'100%',}}>
                <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />
                    <hr/>
                    <ControlledTreeView un={'tree one'} deux={'tree two'} trois={'tree three'} quatre={'tree four'} cinq={'tree five'} />                   
                </div>
            </TabPanel>
        </div>

        </div>
      
    );
}