import React, {Component} from 'react';
import './ServicePage.css';
import NavBar2 from "../../components/Navbar/Navbar2";
import {Image} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { createMuiTheme,ThemeProvider } from '@material-ui/core/styles';
import people from './Images/people-talking-1876726_1920.jpg';
import missile from './Images/rocket.svg';
import taxi from './Images/taxi.svg';
import paquet from './Images/grocery.svg';
import map from './Images/city-map.svg';
import marketing from './Images/marketing-strategy.svg';
import mapStrategy from './Images/ads.svg';
import bouclier from './Images/shield.svg';
import building from './Images/architecture-and-city.svg';
import TransportCarou from "./TransportCarou";
import PainCake from './Images/pancakes-2291908_1920.jpg';
import plat from './Images/restaurant-1284351_1920.jpg';
import chauffeur from './Images/car-1149997_1920.jpg';
import besklet from './Images/isolation-5151098_1920.jpg';
import kayeset from './Images/architecture-1837176_1920.jpg';
import ingenieur from './Images/engineer-4904908_1920.jpg';
import train from './Images/subway-2893851_1920.jpg';
import pc from './Images/office-2717014_1920.jpg';
import camion from './Images/truck-2677373_1920.jpg';
import city from './Images/city-690158_1920.jpg';
import Footter from "../../components/Footer/Footter";
import ArrowTop from "../../components/ArrowTop/ArrowTop";


function ServicePage () {
        const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#82BB25',
    },

  },
});
        return (
            <div>
                <NavBar2/>
                <div style={{ marginTop:'66px' }} className={'container-fluid'}>
                    <div className={'row'} >
                        <div style={{padding:'0px 0px 0px 0px'}} className={ 'col-md-5 col-12'}>
                            <Image src={people}  fluid/>
                        </div>
                         <div style={{backgroundColor:'#F8F8FF'}} className={'col-md-7 col-12  '}>
                             <div style={{marginLeft:'3%',marginTop:'6%'}}>
                                  <h2 className={'ServiceTitle'} style={{fontWeight:'bold',fontSize:'4.5em',color:'#82BB25',textAlign:'center'}} >Notre technologie au<br/> service de la société</h2>
                             <h4 style={{textAlign:'center'}}>Prenez de bonnes habitudes</h4>
                                 <div style={{display:'flex',justifyContent:'center'}}>
 <ThemeProvider theme={theme}>
        <Button variant="contained" color="primary"  style={{color:'white',borderRadius:'1px',marginTop:'3%' }} >Télécharger l'application</Button>
                              </ThemeProvider>
                                 </div>
                             </div>

                        </div>
                    </div>
                </div>
                <div style={{textAlign:'left', marginLeft:'2%',marginTop:'4%'}}>
<h2 style={{color:'#82BB25',fontWeight:'bold',textAlign:'center'}}>Les solutions mises à disposition par notre entreprise :</h2>
                </div>



                  <div className="container px-4 py-5" id="featured-3">
        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
          <div className="feature col">
            <div className="feature-icon ">
              <img src={taxi} className="bi" width="70" height="70"/>
            </div>
            <h5 style={{fontWeight:'bold'}}>Yalla Drive</h5>
            <p>Accédez aux différentes options de courses de n’importe où .</p>
          </div>
          <div className="feature col">
            <div className="feature-icon ">
              <img src={paquet} className="bi" width="70" height="70"/>
            </div>
            <h5 style={{fontWeight:'bold'}}>Yalla Delivery</h5>
            <p>Commander vos repas ou courses sans bouger d’un pouce .</p>
          </div>
          <div className="feature col">
            <div className="feature-icon ">
              <img src={missile} className="bi" width="70" height="70"/>
            </div>
            <h5 style={{fontWeight:'bold'}}>Des revenus supplémentaires</h5>
            <p>Rendez-vous disponible pour profiter d’une rémunération complémentaire .</p>
          </div>
             <div className="feature col">
            <div className="feature-icon ">
              <img src={map} className="bi" width="70" height="70"/>
            </div>
            <h5 style={{fontWeight:'bold'}}>Des villes 2.0</h5>
            <p>Le transport est un besoin primaire et un partenariat avec les municipalités est primordial .</p>
          </div>

          <div className="feature col">
            <div className="feature-icon ">
              <img src={marketing} className="bi" width="70" height="70"/>
            </div>
            <h5 style={{fontWeight:'bold'}}>Une stratégie logistique</h5>
            <p>Des nouveaux services sont prévus pour faire bénéficier ces services aussi aux entreprises .</p>
          </div>
          <div className="feature col">
            <div className="feature-icon ">
              <img src={mapStrategy} className="bi" width="70" height="70"/>
            </div>
            <h5 style={{fontWeight:'bold'}}>Profitez de notre flotte</h5>
            <p>Cette flotte pourra, dans un futur proche, être mise à disposition pour des campagne de communication .</p>
          </div>
        </div>
      </div>

                <div>
                    <h2 style={{textAlign:'center',textAlign:'left', marginLeft:'2%',marginTop:'4%',fontWeight:'bold',color:'#82BB25'} }>De multiples services de transport :</h2>
                    <h5 style={{textAlign:'left', marginLeft:'2%',fontWeight:'bold'}}>Une multitude de choix pour toutes les bourses</h5>
                </div>

                <div style={{marginTop:'3%'}}>
                    <TransportCarou/>
                </div>

               <div style={{marginTop:'5%'}} className={'container-fluid'} >
                   <div className={'row'}>
                       <div className={'col-12 col-md-6'}>
 <div className="feature col">
            <div className="feature-icon ">
              <img src={bouclier} className="bi" width="70" height="70"/>
            </div>
            <h5 style={{fontWeight:'bold'}}>Sentez vous en sécurité</h5>
            <p>Toutes les solutions sont mises en oeuvre afin de vous permettre de prendre les transports en toutes sécurité .</p>
          </div>
                       </div>

                       <div  className={'col-12 col-md-6'}>
 <div className="feature col">
            <div className="feature-icon ">
              <img src={building} className="bi" width="70" height="70"/>
            </div>
            <h5 style={{fontWeight:'bold'}}>Améliorer le train de vie de la population</h5>
            <p>Notre but : améliorer le train de vie de la population de ces villes en collaborant avec la totalité parties-prenantes publiques comme privées .</p>
          </div>
                       </div>
                   </div>
               </div>

                 <div style={{textAlign:'left', marginLeft:'2%',marginTop:'3%'}}>
<h2 style={{color:'#82BB25',fontWeight:'bold',textAlign:'center' }}>Consommez à distance :</h2>
                </div>

                   <div style={{marginTop:'4%'}} className={'container-fluid'} >
                   <div className={'row'}>
                       <div className={'col-12 col-md-6'}>
 <div className="feature col">
            <div >

              <Image className='shadow p-3 mb-5 bg-white rounded' src={PainCake}  fluid/>
            </div>
            <div style={{marginTop:'2%'}} >

           <h5 style={{fontWeight:'bold'}}>Clients</h5>
            <p>Commandez ce que vous voulez, depuis votre canapé. Tout cela en levant juste le petit doigt. Votre commerçant s'occupera de tout à votre place et un coursier s'occupera de vous l'amener à bon port .</p>
     </div>
          </div>
                       </div>

                       <div  className={'col-12 col-md-6'}>
 <div className="feature col">
            <div >
              <Image className='shadow p-3 mb-5 bg-white rounded' src={plat}  fluid/>
            </div>
     <div style={{marginTop:'2%'}} >
           <h5 style={{fontWeight:'bold'}}> Restaurants</h5>
            <p>Mettez vos plats dans notre application afin d'augmenter votre visibilité au sein de notre base client et ainsi augmenter votre CA et votre clientéle habituée. Elargissez donc vos horizons et ne vous préoccupez plus de gérer la livraison .</p>
     </div>

          </div>
                       </div>
                   </div>
               </div>

                   <div style={{textAlign:'left', marginLeft:'2%',marginTop:'4%'}}>
<h2 style={{color:'#82BB25',fontWeight:'bold',textAlign:'center'}}>Complétez vos revenus :</h2>
                </div>

                   <div style={{marginTop:'5%'}} className={'container-fluid'} >
                   <div className={'row'}>
                       <div className={'col-12 col-md-6'}>
 <div className="feature col">
            <div >
              <Image className='shadow p-3 mb-5 bg-white rounded' src={chauffeur}  fluid/>
            </div>
            <div style={{marginTop:'2%'}} >
           <h5 style={{fontWeight:'bold'}}>Chauffeurs </h5>
            <p>Rentabilisez au maximum votre temps d'activités en reduisant votre temps de rotation non effectif sur route. Bénéficier de notre réseau d'utilisateurs important permettant une mise en relation directe favorisant la satisfaction client .</p>
     </div>

          </div>
                       </div>

                       <div  className={'col-12 col-md-6'}>
 <div className="feature col">
            <div >
              <Image className='shadow p-3 mb-5 bg-white rounded' src={besklet}  fluid/>
            </div>
     <div style={{marginTop:'2%'}} >
           <h5 style={{fontWeight:'bold'}}>Livreurs</h5>
            <p>Livrez les repas ou les courses de la clientèle afin de vous voir générer, voir compléter, vos revenus de manière avantageuse et non négligeable. Tout cela à votre rythme, à vos horaires .</p>
     </div>

          </div>
                       </div>
                   </div>
               </div>


                   <div style={{textAlign:'left', marginLeft:'2%',marginTop:'4%'}}>
<h2 style={{color:'#82BB25',fontWeight:'bold',textAlign:'center'}}>Des villes en constante évolution :</h2>
                </div>

                   <div style={{marginTop:'5%'}} className={'container-fluid'} >
                   <div className={'row'}>
                       <div className={'col-12 col-md-6'}>
 <div className="feature col">
            <div >
              <Image className='shadow p-3 mb-5 bg-white rounded' src={kayeset}  fluid/>
            </div>
            <div style={{marginTop:'2%'}} >
           <h5 style={{fontWeight:'bold'}}>Décongestion du traffic   </h5>
            <p>Participez au développement durable en favorisant l'économie de trajet des professionnels des transports. Cela améliorera tout aussi grandement la fluidité du traffic automobile au sein des villes .      </p>
     </div>
          </div>
                       </div>

                       <div  className={'col-12 col-md-6'}>
 <div className="feature col">
            <div >
              <Image className='shadow p-3 mb-5 bg-white rounded' src={ingenieur}  fluid/>
            </div>
     <div style={{marginTop:'2%'}} >
           <h5 style={{fontWeight:'bold'}}>Services de mobilité</h5>
            <p>accès aux services de transport et de livraison de nourritures ou courses à distance favorise l'implication sociétale des personnes à mobilité réduite en leur mettant à disposition facilement ces différents produits .</p>
     </div>

          </div>
                       </div>
                   </div>
               </div>



                   <div style={{textAlign:'left', marginLeft:'2%',marginTop:'4%'}}>
<h2 style={{color:'#82BB25',fontWeight:'bold',textAlign:'center'}}>Une optimisation des services des tous :</h2>
                </div>

                   <div style={{marginTop:'5%'}} className={'container-fluid'} >
                   <div className={'row'}>
                       <div className={'col-12 col-md-6'}>
 <div className="feature col">
            <div >
              <Image className='shadow p-3 mb-5 bg-white rounded' src={train}  fluid/>
            </div>
            <div style={{marginTop:'2%'}} >
           <h5 style={{fontWeight:'bold'}}> Transports en communs </h5>
            <p>Des optimisations sont à prévoir en collaboration avec les entités, publiques comme privées, afin de rendre la vie des gens des villes plus agréables et plus accessibles, dans l'objectif du développement durable
</p>
     </div>
          </div>
                       </div>

                       <div  className={'col-12 col-md-6'}>
 <div className="feature col">
            <div >
              <Image className='shadow p-3 mb-5 bg-white rounded' src={pc}  fluid/>
            </div>
     <div style={{marginTop:'2%'}} >
           <h5 style={{fontWeight:'bold'}}>Statistiques</h5>
            <p> Toutes les statistiques utiles à l'optimisation de la logistique, urbaine dans un premier temps, nationale à finalité, seront accessibles à toutes les parties prenantes, sur demande, justifiée par une stratégie de bien d'intérêt public.</p>
     </div>

          </div>
                       </div>
                   </div>
               </div>



                   <div style={{textAlign:'left', marginLeft:'2%',marginTop:'4%'}}>
<h2 style={{color:'#82BB25',fontWeight:'bold',textAlign:'center'}}>Des futurs services connexes :</h2>

                </div>

                   <div style={{marginTop:'5%'}} className={'container-fluid'} >
                   <div className={'row'}>
                       <div className={'col-12 col-md-6'}>
 <div className="feature col">
            <div >
              <Image className='shadow p-3 mb-5 bg-white rounded' src={camion}  fluid/>
            </div>
            <div style={{marginTop:'2%'}} >
           <h5 style={{fontWeight:'bold'}}>Logistique professionelle</h5>
            <p>De nouvelles fonctionnalités ou de nouveaux produits sont prévus afin de faciliter la vie logistique des entreprises ayant le transport comme partie-prenante et intégrante de leur activité .</p>
     </div>
          </div>
                       </div>

                       <div  className={'col-12 col-md-6'}>
 <div className="feature col">
            <div >
              <Image className='shadow p-3 mb-5 bg-white rounded' src={city}  fluid/>
            </div>
     <div style={{marginTop:'2%'}} >
           <h5 style={{fontWeight:'bold'}}> Campagnes de communication</h5>
            <p> Plus notre flotte grandit, plus son utilisation pour des campagnes de communication ou autres seront accessibles à la population afin d'améliorer la visibilité de certaines campagnes tout en permettant aux partenaires de générer des revenus supplémentaires .</p>
     </div>

          </div>
                       </div>
                   </div>
               </div>
<ArrowTop/>
<div style={{marginTop:'6%'}} >
    <Footter/>
</div>
            </div>
        );

}

export default ServicePage;