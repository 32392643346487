import React from 'react';
import 'leaflet/dist/leaflet.css';
import './LeafletPages.css';
import {
    TileLayer,
    Circle,
    MapContainer,
    Popup,
    CircleMarker,
    Polygon

} from 'react-leaflet';


function LeafletKelibia () {
    const center = [36.8401246,11.0613004]

    {/*






    */}

    const fillBlueOptions = { fillColor: 'blue' }
    const redOptions = { color: 'red' }
    const limeOptions = { color: 'lime' }
    return (


        <div className={'LeafletMap'} >
            <MapContainer center={center} zoom={10} scrollWheelZoom={false}>
                <TileLayer
                    style={{color:'black !important'}}
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polygon positions={[[36.69085, 10.96513],[36.69085, 10.96513],[36.91784, 10.76309],[37.05443, 10.89834],[37.10154, 11.05626],[36.87879, 11.15857],[36.82164, 11.1215]]} color="blue" />
                <Circle center={center} pathOptions={fillBlueOptions} radius={200} />

            </MapContainer>
        </div>

    );

}

export default LeafletKelibia;


