import React, { Component } from 'react';
import Footer from '../../components/Footer/Footter';
import Navbar2 from '../../components/Navbar/Navbar2';
import './Chauffeur.css';
import NavItems from './../../components/NavPills/SecondNav/SecondNav';
import ArrowTop from "../../components/ArrowTop/ArrowTop";

class Chauffeur extends Component {
  render() { 
    return (
      <div>
        <div >
        <Navbar2/>
        <div style={{marginTop:'66px'}} >
        <NavItems/>
        </div>
            <ArrowTop/>
        </div>
      

        
      
      <div style={{marginTop:'10%'}}>
      <Footer  />
      </div>
      
      </div>
    );
  }
}
 
export default Chauffeur;