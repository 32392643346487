import React, { Component } from 'react';
import {Navbar , Nav , Form} from 'react-bootstrap';

class SupportNav extends Component {
    render() { 
        return (
            <div>
                <Navbar bg="light" expand="lg">
  <Navbar.Brand href="#home"><b><a style={{textDecoration:'none',color:'black'}} href="/">Yalla</a></b> Support</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
    <form >
                        <div  className="form-row align-items-center">
                            <div className="col-auto my-1">
                                <select className="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                    <option selected>Ville</option>
                                    <option value={1}>Tunis</option>
                                    <option value={2}>Sousse</option>
                                    <option value={3}>Sfax</option>
                                    <option value={3}>jendouba</option>
                                    <option value={4}>Kef</option>
                                    <option value={5}>Béja</option>
                                    <option value={6}>Kairouan</option>
                                    <option value={7}>Kélibia</option>
                                    <option value={8}>Nabeul</option>
                                    <option value={9}>Tataouin</option>
                                    <option value={10}>Médenine</option>
                                </select>
                            </div>
                        </div>
                    </form>
    </Nav>

    <Form inline>
      <button className='btn' style={{backgroundColor:'#82bb25',borderRadius:'0px'}} ><spans style={{color:'white'}}>Inscription</spans></button>&nbsp;&nbsp;&nbsp;
      <button className='btn' style={{backgroundColor:'#82bb25',borderRadius:'0px'}} ><spans style={{color:'white'}}>Connextion</spans></button>
    </Form>
  </Navbar.Collapse>
</Navbar>
            </div>
        );
    }
}
 
export default SupportNav;