import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import IconText2 from "../../components/IconText2/IconText2";
import scooter from './Images/scooter.svg';
import taxi from './Images/taxi.svg';
import ElectroCar from './Images/car.svg';
import OrangeAuto from './Images/automobile.svg';
import RedCar from './Images/sports-car.svg';
import bus from './Images/buses.svg';


class TransportCarou extends Component {

state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    }

    render() {
        return (
            <div>
             <div  style={{marginTop:'30px',backgroundColor:'#82bb25',height:'30px'}} >
                    </div>
<OwlCarousel
    className="owl-theme"
    loop
    margin={0}
    nav
    items={6}
    autoplay
    dots={false}
    style={{backgroundColor:'#82bb25'}}
    responsive={this.state.responsive}
>
    <div    class="item">
        <IconText2 img={scooter} title={'Scooter'} text2={'Abordable pour une personne uniquie'} width={150} height={150} />
     </div>
     <div class="item"  >
             <IconText2 img={taxi} title={'Taxi'} text2={'Economique au prix régulié par les autorités'} width={150} height={150} />
    </div>
    <div   class="item">
            <IconText2 img={ElectroCar} title={'Green'} text2={"Apporter votre pierre à l'édifice de l'écologie priviligégiant les véhicules éléctrifiés"} width={150} height={150} />
     </div>

     <div class='item' >
             <IconText2 img={OrangeAuto} title={'Premium'} text2={"Des berlines de villes modernes et spacieuses afin de profiter d'un transport de qualité"} width={150} height={150} />
    </div>

    <div  class="item">
            <IconText2 img={RedCar} title={'Luxe'} text2={'Un plaisir de route haut de gamme pour des véhicules équivalents'}  width={150} height={150} />
    </div>
    <div class="item">
            <IconText2 img={bus} title={'Bus'} text2={"La logistique de base à reinventer. un partenariat à prévoir avec les transports en commun"} width={150} height={150} />
    </div>



</OwlCarousel>
            </div>
        );
    }
}

export default TransportCarou;