import React from 'react';
import 'leaflet/dist/leaflet.css';
import './LeafletPages.css';
import {
    TileLayer,
    Circle,
    MapContainer,
    Popup,
    CircleMarker,
    Polygon

} from 'react-leaflet';


function LeafletHammamet () {
    const center = [36.4058188,10.6032656]

    {/*










    */}

    const fillBlueOptions = { fillColor: 'blue' }
    const redOptions = { color: 'red' }
    const limeOptions = { color: 'lime' }
    return (


        <div className={'LeafletMap'} >
            <MapContainer center={center} zoom={10} scrollWheelZoom={false}>
                <TileLayer
                    style={{color:'black !important'}}
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polygon positions={[[36.42434, 10.6848],
                    [36.44358, 10.67085],
                    [36.42889, 10.62474],
                    [36.45025, 10.56451],
                    [36.43247, 10.53486],
                    [36.41013, 10.53365],
                    [36.35883, 10.50425],
                    [36.34909, 10.53446],
                    [36.38182, 10.62183]



                ]} color="blue" />
                <Circle center={center} pathOptions={fillBlueOptions} radius={200} />

            </MapContainer>
        </div>

    );

}

export default LeafletHammamet;


