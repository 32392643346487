import React, {Component} from 'react';
import NavBar2 from "../../components/Navbar/Navbar2";
import RestaurantPills from "./RestaurantPills";
import Footter from "../../components/Footer/Footter";

class Restaurants extends Component {
    render() {
        return (
            <div>
                <NavBar2/>
                <div style={{marginTop:'66px'}}>
                                    <RestaurantPills/>
                </div>
                <div style={{marginTop:'9%'}}>
                    <Footter/>
                </div>

            </div>
        );
    }
}

export default Restaurants;