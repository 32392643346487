import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import IconText from '../IconText/Icontext';
import Bizerte from './Icons/Bizerte.svg';
import Gabes from './Icons/Gabes.svg';
import Kairouan from './Icons/Kairouan.svg';
import Sousse from './Icons/Sousse.svg';
import Sfax from './Icons/Sfax.svg';
import Tabarka from './Icons/Tabarka.svg';
import Tunis from './Icons/Tunis.svg';
import Nabeul from './Icons/Nabeul.svg';
import Gafsa from './Icons/Gafsa.svg';
import Hammamet from './Icons/Hammamet.svg';
import Touzeur from './Icons/Touzeur.svg';
import Mestir from './Icons/Mestir.svg';
import Kelibia from './Icons/Kelibia.svg';
import Djerba from './Icons/Djerba.svg';

class ElaCarou2 extends Component {

state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    }

    render() { 
        return (
            <div>
             <div  style={{marginTop:'30px',backgroundColor:'#82bb25',display:'flex',justifyContent:'center',height:'50px'}} >
                    <h5 style={{color:'white',marginTop:'8px'}}>Nous servons actuellement 14 villes en Tunisie</h5>
                    </div>
<OwlCarousel
    className="owl-theme"
    loop
    margin={0}
    nav
    items={6}
    autoplay
    dots={false}
    style={{backgroundColor:'#82bb25'}}
    responsive={this.state.responsive}
>
    <div id={'IconCity'}   class="item">
    <a style={{textDecoration:'none'}} href={'/LocationTunis'} ><IconText img={Tunis} text2={'Tunis'} width={150} height={150} /></a>
     </div>
     <div class="item"  >
         <a style={{textDecoration:'none'}} href={'/LocationSousse'} >
             <IconText img={Sousse} text2={'Sousse'} width={150} height={150} />
         </a>
    </div>
    <div   class="item">
        <a style={{textDecoration:'none'}} href={'/LocationGabes'} >
            <IconText img={Gabes} text2={'Gabes'} width={150} height={150} />
        </a>
     </div>

     <div class='item' >
         <a style={{textDecoration:'none'}} href={'/LocationGafsa'} >
             <IconText img={Gafsa} text2={'Gafsa'} width={150} height={150} />
         </a>
    </div>
    
    <div  class="item">
        <a style={{textDecoration:'none'}} href={'/LocationHammamet'} >
            <IconText img={Hammamet} text2={'Hammamet'} width={150} height={150} />
        </a>
    </div>
    <div class="item">
        <a style={{textDecoration:'none'}} href={'/LocationNabeul'} >
            <IconText img={Nabeul} text2={'Nabeul'} width={150} height={150} />
        </a>
    </div>
    
    <div   class="item">
        <a style={{textDecoration:'none'}} href={'/LocationSfax'} >
            <IconText img={Sfax} text2={'Sfax'} width={150} height={150} />
        </a>
    </div>
    <div class='item' >
        <a style={{textDecoration:'none'}} href={'/LocationTabarka'} >
            <IconText img={Tabarka} text2={'Tabarka'} width={150} height={150} />
        </a>
    </div>
    <div  class="item">
        <a style={{textDecoration:'none'}} href={'/LocationBizerte'} >
            <IconText img={Bizerte} text2={'Bizerte'} width={150} height={150} />
        </a>
    </div>
    <div class="item">
        <a style={{textDecoration:'none'}} href={'/LocationKairouan'} >
            <IconText img={Kairouan} text2={'Kairouan'} width={150} height={150} />
        </a>
    </div>
    <div  class="item">
        <a style={{textDecoration:'none'}} href={'/LocationTouzeur'} >
            <IconText img={Touzeur} text2={'Touzeur'} width={150} height={150} />
        </a>
    </div>
    <div class='item' >
        <a style={{textDecoration:'none'}} href={'/LocationMonastir'} >
            <IconText img={Mestir} text2={'Monastir'} width={150} height={150} />
        </a>
    </div>
    <div className='item'>
        <a style={{textDecoration:'none'}} href={'/LocationKelibia'}>
            <IconText img={Kelibia} text2={'Kelibia'} width={150} height={150}/>
        </a>
    </div>
    <div className='item'>
        <a style={{textDecoration:'none'}} href={'/LocationDjerba'}>
            <IconText img={Djerba} text2={'Djerba'} width={150} height={150}/>
        </a>
    </div>
    

</OwlCarousel>
            </div>
        );
    }
}
 
export default ElaCarou2;