import React, {useState} from 'react';
import {Parallax} from "react-parallax";
import costume from "../images/hunters-race-MYbhN8KaaEc-unsplash.jpg";
import Moment from "react-moment";
import {Jobs} from "./JobData";
import MyCard from "../../../components/Card/MyCard";
import {Link} from 'react-router-dom';
import './CarrierPills.css'
function MyJob () {

    const [MyJobs, setMyJobs] = useState(Jobs);

    return (
            <div>
                <Parallax blur={2} style={{width:'100%'}} bgImage={costume} >

                    <div className={'MyParallax'} style={{height:'390px' , marginTop:'20%',marginLeft:'4%'}} >
                        <div className="container">
                            <div className={'DivTitle'} >
                                <h1 className={'AproposTitle'} style={{color:'#F8F9FA',textAlign:'center',
                                    fontFamily:'Poppins,sans-serif',fontWeight:'750',fontSize:'4.5em'}}>
                                    Prêts pour un défi ?
                                </h1>

                            </div>

                        </div>
                    </div>
                </Parallax>

                <div style={{marginTop:'2%',Color:'#F8F9FA',width:'95%',marginRight:'2.5%',marginLeft:'2.5%'}} >
                    <h4 style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >
                        Vous trouverez sur cette page une liste de tous les postes à pouvoir à l’instant T mais aussi tous ceux à prévoir pour notre croissance future.
                        Si une sélection est faite, vous serez contacté quelque soit la décision prise sur votre profil car nous considérons que c’est le moins que nous puissions faire.
                        Dans tous les cas, n’hésitez pas à déposer une candidature sur l’un des postes, car, même s’il n’est pas encore à prévoir, nous le considérerons comme une candidature spontanée et sera traitée au plus vite si votre profil nous paraît très intéressant.

                    </h4>
                </div>

                <div style={{display:'flex',justifyContent:'center',marginTop:'4%'}} className={'filterJob'} >
                    <div  className="form-row align-items-center">
                        <div className="col-auto my-1">
                            <select className="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                <option selected>Métiers</option>
                                <option value={1}>Infographiste</option>
                                <option value={2}>Développeur</option>
                                <option value={3}>Ingénieur</option>
                                <option value={3}>Chauffeur</option>
                            </select>
                        </div>
                    </div>

                    <div  className="form-row align-items-center">
                        <div className="col-auto my-1">
                            <select className="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                <option selected>Villes</option>
                                <option value={1}>Tunis</option>
                                <option value={2}>Sousse</option>
                                <option value={3}>Sfax</option>
                                <option value={3}>jendouba</option>
                                <option value={4}>Kef</option>
                                <option value={5}>Béja</option>
                                <option value={6}>Kairouan</option>
                                <option value={7}>Kélibia</option>
                                <option value={8}>Nabeul</option>
                                <option value={9}>Tataouin</option>
                                <option value={10}>Médenine</option>
                            </select>
                        </div>
                    </div>



                    <div   className="form-row align-items-center">
                        <div className="col-auto my-1">
                            <select className="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                <option selected>Contrats</option>
                                <option value={1}>CDI</option>
                                <option value={2}>CDD</option>
                                <option value={3}>CVP</option>
                            </select>
                        </div>
                    </div>
<button className={'btn'} style={{backgroundColor:'#82bb25',color:'white',borderRadius:'0px'}}>Rechercher</button>
                </div>
<div className={'JobsCard'} style={{marginTop:'5%'}}>
                    {MyJobs.map((job)=>
                    <Link  style={{textDecoration:'none'}} to={`/JobPage/${job.Titre}`}>

                        <MyCard className='' title={job.Titre}
                                description={job.Description.slice(0, 100)+'...'}
                                picture={job.Image}
                                date={
                                    <Moment
                                        format="YYYY-MM-DD HH:mm"
                                        style={{ fontWeight: "normal", fontSize: "14px" }}
                                    >
                                        {job.Date}
                                    </Moment>

                                }/>
                                </Link>

                    )}


                </div>
               

            </div>
        );

}

export default MyJob;