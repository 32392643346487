import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import './TreeView.css';

const useStyles = makeStyles({
  root: {
  },
});

export default function ControlledTreeView(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
    
      <TreeItem nodeId="1"    label={<a>{props.un}</a>}>
  {/*                                      */}
        <TreeItem nodeId="2"  label={<a>{props.unun}</a>}>
        <TreeItem nodeId="3"  label={<a>{props.unun}</a>}>

</TreeItem>
 <TreeItem nodeId="4"  label={<a>{props.unun}</a>}>

</TreeItem>
<TreeItem nodeId="5"  label={<a>{props.unun}</a>}>

</TreeItem>
<TreeItem nodeId="6"  label={<a>{props.unun}</a>}>

</TreeItem>
          </TreeItem>
  {/*                                      */}
          <TreeItem nodeId="" label={<a>{props.unun}</a>}>

          </TreeItem>
  {/*                                      */}
          <TreeItem nodeId="" label={<a>{props.unun}</a>}>

          </TreeItem>
  {/*                                      */}
          <TreeItem nodeId="" label={<a>{props.unun}</a>}>

          </TreeItem>
  {/*                                      */}
          <TreeItem nodeId="" label={<a>{props.unun}</a>}>

          </TreeItem>

        </TreeItem>
    </TreeView>
  );
}