import React, {Component} from 'react';
import './NewsLetter.css';

class NewsLetter extends Component {
    render() {
        return (
            <div>
                 <section className="newsletter">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="content">
                <form>
                  <h2>Abonnez-vous à notre newsletter</h2>
                  <div className="input-group">
                    <input type="email" className="form-control" placeholder="Votre email" />
                    <span className="input-group-btn">
                      <button className="btn" type="submit">S'abboner</button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
            </div>
        );
    }
}

export default NewsLetter;