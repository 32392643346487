import React, { Component } from 'react';
import {Image} from 'react-bootstrap';
import Principale from '../images/Principale.jpg';
import Tunis from '../images/tunis.jpg';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import {Parallax} from 'react-parallax';
import Fade from 'react-reveal/Fade';
import Button from '@material-ui/core/Button';
import { createMuiTheme,ThemeProvider } from '@material-ui/core/styles';
import './PagesPills.css';


class Inscription extends Component {
    render() {

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#82BB25',
    },

  },
});
        return (
            <div>
             <div className='Parallax'>
             <Parallax blur={2} bgImage={Principale} >
                 <div className={'ChauffParallax'} style={{height:'501px'}} >
                     <div className={'DivTitlle'}>
                           <h1 className={'ChauffeurTitle'} style={{color:'white',textAlign:'center',
            fontFamily:'Poppins,sans-serif',fontWeight:'750',fontSize:'4.5em',marginTop:'19%'}}>
                                 Devenez Chauffeur<br/> Taxi  Chez Yalla
                                 </h1>
                     </div>
                 </div>
             </Parallax>
             </div>
             <Slide timeout={1500} left>

    <div style={{marginTop:'6%'}} >
        <b><h1 style={{textAlign:'center'}} >Augmentez vos revenus de manière substantielle grâce à Yalla !</h1></b> 
    </div>
    </Slide>

<div className='TunisText' >

<div className={'avantages'} style={{ width:'90%' , marginRight:'5%' , marginLeft:'5%', marginTop:'5%'}} >



   <div className='TextButton' >
       <Slide timeout={1500} right>
  <h2 style={{textAlign:'center'}} > Rejoignez aujourd’hui la flotte Yalla pour profiter du service de mise en relation client le plus juste du marché ... </h2>
       </Slide>
<div className='DuoButton' style={{display:'flex',justifyContent:'center',marginTop:'3%'}}>
    <Bounce timeout={1500} >
                      <ThemeProvider theme={theme}>

<Button  variant="contained" color="primary"  style={{color:'white',borderRadius:'1px' }}  >Inscription</Button>&nbsp;&nbsp;&nbsp;&nbsp;
 <Button variant="contained" color="primary"  style={{color:'white',borderRadius:'1px'  }}  >Connexion</Button>
                      </ThemeProvider>
    </Bounce>
</div>

<div className='SubTitle-Button' style={{marginTop:'5%'}} >
<div className='subTitle'>
    <Zoom timeout={1500} >
        <h2  style={{textAlign:'center'}} >Comprenez le système de tarification et de commission Yalla</h2>
    </Zoom>

</div>
<div style={{display:'flex',justifyContent:'center',marginTop:'3%'}} className='lastButton' >
    <Fade timeout={1500} >
                              <ThemeProvider theme={theme}>
        <Button variant="contained" color="primary"  style={{color:'white',borderRadius:'1px' }} className='btn' >Revenus de courses</Button>
                              </ThemeProvider>
    </Fade>

</div>

</div>
</div>


</div>
</div>
<div style={{marginTop:'10%'}}  className="container">

<div className='row flex-column-reverse flex-md-row  ' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6 ' style={{marginTop:'6%'}} >
 <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Où nous trouver ?</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >L’accueil de Yalla est ouvert du lundi au vendredi de 9h à 17h à l’adresse suivante :
12 rue Mohamed Mohamed, 1075 Mohamed City Auquel cas, notre équipe est
toujours joignable par mail à l’adresse suivante :
mohamed@mohamed.com</p>
</div>
</Slide>

<Slide right timeout={1000} >
<div className='col-12 col-md-6 ' >
                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={Tunis} fluid/>

</div>
</Slide>




</div>
      
     </div>

            </div>
        );
    }
}
 
export default Inscription;