import React, { Component } from 'react';
import './NavBar.css' ;
import Yalla from './icons/YallaLogo.png' ;
import services from './icons/app.svg';
import job from './icons/job-search.svg';
import {NavDropdown,Navbar,Nav,Form} from 'react-bootstrap';
import {FaLanguage} from 'react-icons/fa';
import { ModalChoiceInscrit } from './../ModalChoice/ModalChoice';
import { ModalChoiceLogin } from '../ModalChoice/ModalChoiceLogin';
import Porto from './icons/portugal.png';
import Fran from './icons/france.png';
import Brit from './icons/united-kingdom.png';
import {GrRestaurant} from 'react-icons/gr';
import {FaTaxi} from 'react-icons/fa';
import {MdLocalGroceryStore} from 'react-icons/md';
import {RiEBike2Fill} from 'react-icons/ri';
import {RiTeamFill} from 'react-icons/ri';
import {AiFillSetting} from 'react-icons/ai';
import {RiArticleFill} from 'react-icons/ri';
import {IoMdHelpCircle} from 'react-icons/io';
import {RiRestaurant2Fill} from 'react-icons/ri'
import {GiPuzzle} from 'react-icons/gi' ;

function NavBar2 (props) {

      const portugal = <img src={Porto} width="20" height="20" />
      const Britan = <img width="20" height="20" src={Brit} />
      const France = <img width="20" height="20" src={Fran} />



        return (
        <div>
          
          <Navbar className='fixed-top navbar-second ShadowNav' style={{backgroundColor:'#82bb25',zIndex:9999}} expand="lg">
  <Navbar.Brand className='navbar-brand-second'   href="/"><img src={Yalla} height='40' width='80' /></Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link  href="/ClientPage"><span style={{color:'white',fontWeight:'bold'}} >Clients</span></Nav.Link>

      <NavDropdown  title={<span style={{color:'white',fontWeight:'bold'}} >Partenaires</span>} id="basic-nav-dropdown">
        <NavDropdown.Item href="/Chauffeur"><FaTaxi/>{' '}Chauffeur</NavDropdown.Item>
          <NavDropdown.Item href="/Restaurant"><RiRestaurant2Fill/>{' '}Restaurants</NavDropdown.Item>
      </NavDropdown>
      
      <NavDropdown   title={<span  style={{color:'white',fontWeight:'bold'}} >Aides</span>} id="basic-nav-dropdown">
        <NavDropdown.Item href="/Apropos"><RiTeamFill/>{' '}À propos de nous</NavDropdown.Item>
        <NavDropdown.Item href="/Fonctionnement"><AiFillSetting/>{' '}Fonctionement</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3"><RiArticleFill/>{' '}Espace presse</NavDropdown.Item>
        <NavDropdown.Item href="/Support"><IoMdHelpCircle/>{' '}Support</NavDropdown.Item>
        <NavDropdown.Item href="/Service"><GiPuzzle/>{' '}Nos services</NavDropdown.Item>
      </NavDropdown>
      
    </Nav>
    <Form   inline>
    <Nav className="mr-auto">
    <NavDropdown  title={<FaLanguage size={33} />}
                       id="nav-dropdown">
        <NavDropdown.Item eventKey="4.1">
          <span >{Britan}{' '}Anglais</span>
        </NavDropdown.Item>
<NavDropdown.Item eventKey="4.2">
          {France}{' '}Français
        </NavDropdown.Item>
        <NavDropdown.Item eventKey="4.3">
            {portugal}{' '}Portugais
        </NavDropdown.Item>

      </NavDropdown>
      <Nav.Link  href="/carrier">
      <img width="25" height="25" src={job} alt="cas" title="cas" class="loaded"/>       {' '} <span style={{color:'white',fontWeight:'bold'}} >Carrières</span>  </Nav.Link>
      <Nav.Link  href="">
      <img width="25" height="25" src={services} alt="menu" title="menu" class="loaded"/>{" "}
        <span style={{color:'white',fontWeight:'bold'}} >Services</span></Nav.Link>
        <Nav.Link>
        <ModalChoiceLogin/>
        </Nav.Link>
      
        <ModalChoiceInscrit/>
      </Nav>
    </Form>
  </Navbar.Collapse>
</Navbar>
            </div>
        );

}
 
export default NavBar2;