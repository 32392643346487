import React, { Component } from 'react';
import {FaFacebookSquare} from 'react-icons/fa'; 
import {RiInstagramFill} from 'react-icons/ri';
import {GrTwitter} from 'react-icons/gr';
import {ImLinkedin} from 'react-icons/im';


class Footer2 extends Component {
    render() { 
        return (
            <div>
                <footer style={{backgroundColor:'grey'}} className=" text-white text-center text-lg-start">
        {/* Grid container */}
        <div className="container p-4">
          {/*Grid row*/}
          <div className="row">
            {/*Grid column*/}
            <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
              <h3 ><b>Yalla</b> support</h3>
              <h6 style={{textAlign:'left'}} >
              Vous avez des questions sur un sujet spécifique ? 
              Nous fournissons toutes les solutions et réponses qui existent pour vous guider et 
              répondre à la plupart de vos questions et suggérer des solutions, si vous ne trouvez pas
               une réponse précise, vous pouvez nous Contacté .
              </h6>
            </div>
            {/*Grid column*/}
            {/*Grid column*/}
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase">Entreprise</h5>
              <ul className="list-unstyled mb-0">
                <li>
                  <a href="#!" className="text-white">Carrière</a>
                </li>
                <li>
                  <a href="#!" className="text-white">Espace Presse</a>
                </li>
                <li>
                  <a href="#!" className="text-white">Contact</a>
                </li>
                <li>
                  <a href="#!" className="text-white">Blog</a>
                </li>
              </ul>
            </div>
            {/*Grid column*/}
            {/*Grid column*/}
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase mb-0">Suivez-nous</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#!" className="text-white"><FaFacebookSquare/></a>
                </li>
                <li>
                  <a href="#!" className="text-white"><RiInstagramFill/></a>
                </li>
                <li>
                  <a href="#!" className="text-white"><GrTwitter/></a>
                </li>
                <li>
                  <a href="#!" className="text-white"><ImLinkedin/></a>
                </li>
              </ul>
            </div>
            {/*Grid column*/}
          </div>
          {/*Grid row*/}
        </div>
        {/* Grid container */}
        {/* Copyright */}
        <div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
          © 2020 Yalla
        </div>
        {/* Copyright */}
      </footer>
            </div>
        );
    }
}
 
export default Footer2;