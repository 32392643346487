import React from 'react';
import 'leaflet/dist/leaflet.css';
import './LeafletPages.css';
import {
    TileLayer,
    Circle,
    MapContainer,
    Popup,
    CircleMarker,
    Polygon

} from 'react-leaflet';


function LeafletSousse () {
    const center = [35.8284534,10.5480138]

    {/*








    */}

    const fillBlueOptions = { fillColor: 'blue' }
    const redOptions = { color: 'red' }
    const limeOptions = { color: 'lime' }
    return (


        <div className={'LeafletMap'} >
            <MapContainer center={center} zoom={10} scrollWheelZoom={false}>
                <TileLayer
                    style={{color:'black !important'}}
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polygon positions={[[35.78803, 10.68732],
                    [35.69858, 10.58672],
                    [35.71503, 10.55788],
                    [35.73454, 10.55479],
                    [35.79722, 10.55582],
                    [35.87198, 10.49452],
                    [35.95996, 10.42508],
                    [35.97713, 10.53574]

                ]} color="blue" />
                <Circle center={center} pathOptions={fillBlueOptions} radius={200} />

            </MapContainer>
        </div>

    );

}

export default LeafletSousse;


