import {Parallax }from 'react-parallax';
import {Image} from 'react-bootstrap';
import Slide from 'react-reveal/Slide';
import React,{Component} from 'react';
import restaurant from '../Images/restaurant-690975_1920.jpg';
import bar from '../Images/bar-1846137_1920.jpg';
import salmon from "../Images/salmon-518032_1920.jpg";
import coursier from '../Images/Courier.jpg';
import './RestaurantPages.css';


class RestaurantPage extends Component {
    render() {
        return (

            <div  >
                <Parallax  blur={2} style={{width:'100%'}} bgImage={restaurant} >
                          <div className={'RestoParallax'} style={{height:'390px' , marginTop:'20%',marginLeft:'4%'}} >
                          <div className="container">
                              <div className={'DivTite'} style={{}}  >
                                 <h1 className={'RestaurantTitle'} style={{color:'white',textAlign:'center',
            fontFamily:'Poppins,sans-serif',fontWeight:'750',fontSize:'4.5em'}}>
                                 Simplifiez votre <br/> activité
                                 </h1>
                              </div>
        </div>
                          </div>
                      </Parallax>






                      <div style={{marginTop:'10%'}} className="container">

<div className='row' >
<Slide left timeout={1000}>
<div className='col-12 col-md-6' >

                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={bar} fluid/>

                        </div>

                 </Slide>
                 <Slide right timeout={1000}>
        <div style={{marginTop:'8%'}}  className='col-12 col-md-6' >
          <div >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Augmentez votre visibilité :</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} > Devenez partenaire de Yalla et attirez plus de clients. Profitez de la mise en avant sur notre sélection de restaurants afin de booster votre chiffre d’affaire.
</p>
          </div>

        </div>
        </Slide>

</div>

      </div>



      <div style={{marginTop:'10%'}}  className="container">



<div className='row flex-column-reverse flex-md-row  ' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6 ' style={{marginTop:'12%'}} >
 <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Concentrez vous sur votre métier :</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} > Si vous le désirez, ne vous préoccupez que de la préparation de vos repas, nous nous occupons du reste.
</p>
</div>
</Slide>
<Slide right timeout={1000} >
<div className='col-12 col-md-6 ' >
                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={salmon} fluid/>

</div>
</Slide>


</div>

     </div>





      <div style={{marginTop:'10%'}}  className="container">

<div className='row' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6' >
        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={coursier} fluid/>
</div>
</Slide>

<Slide right timeout={1000} >
<div className='col-12 col-md-6' >
          <div style={{marginTop:'20%'}} >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >La visibilité accrue pour les spécialistes:</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Les magasins de niches ou épiceries spécialisées peuvent aussi profiter de cette visibilité pour asseoir leur main mise sur le voisinage.</p>
          </div>
        </div>
        </Slide>
      </div>


</div>
      </div>

        );
    }
}

export default RestaurantPage;