import {Parallax }from 'react-parallax';
import NavBar2 from './../../components/Navbar/Navbar2';
import {Image} from 'react-bootstrap';
import Footter from '../../components/Footer/Footter';
import ArrowTop from '../../components/ArrowTop/ArrowTop';
import Slide from 'react-reveal/Slide';
import React,{Component} from 'react';
import money from './images/mobile-phone-1595784_1920.jpg';
import production from './images/production-4408573_1920.jpg';
import men from './images/men-2425121_1920.jpg';
import binding from './images/binding-contract-948442_1920.jpg';
import './Client.css';


class ClientPage extends Component {
    render() {
        return (

            <div  >
                <NavBar2/>
                <Parallax blur={2} style={{width:'100%'}} bgImage={production} >
                          <div className={'ClientParallax'} style={{height:'390px' , marginTop:'20%',marginLeft:'4%'}} >
                          <div className="container">
                              <div className={'DivTitllle'}  >
                                 <h1 className={'ClientTitle'} style={{color:'white',textAlign:'center',
            fontFamily:'Poppins,sans-serif',fontWeight:'750',fontSize:'4.5em'}}>
                                 Le présent et l'avenir de<br/> la logistique à votre service
                                 </h1>
                              </div>
        </div>
                          </div>
                      </Parallax>






                      <div style={{marginTop:'10%'}} className="container">

<div className='row' >
<Slide left timeout={1000}>
<div className='col-12 col-md-6' >

                        <Image style={{borderRadius:'5px'}}  src={money} fluid/>

                        </div>

                 </Slide>
                 <Slide right timeout={1000}>
        <div style={{marginTop:'8%'}}  className='col-12 col-md-6' >
          <div >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Bénéficier des prix les plus bas et justes du marché :</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Que ce soit pour commander un chauffeur ou de la nourriture ou pour profiter de bons plans dans vos restaurants préférés, Yalla vous propose le service le moins cher. Les commissions les plus basses du marché qui impactent au mieux les tarifs des professionnels.</p>
          </div>

        </div>
        </Slide>

</div>

      </div>



      <div style={{marginTop:'10%'}}  className="container">



<div className='row flex-column-reverse flex-md-row  ' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6 ' style={{marginTop:'12%'}} >
 <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Profitez de vos moments entre amis :</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Préparer vos soirées ou déplacements sur le bout des doigts.  Un grand nombre de restaurants  disponibles pour tous les palais pour savourer ou un chauffeur à disposition dès que vous estimez en avoir besoin, sans prise de tête.</p>
</div>
</Slide>
<Slide right timeout={1000} >
<div className='col-12 col-md-6 ' >
                        <Image style={{borderRadius:'5px'}}  src={men} fluid/>

</div>
</Slide>


</div>

     </div>





      <div style={{marginTop:'10%'}}  className="container">

<div className='row' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6' >
        <Image style={{borderRadius:'5px'}}  src={binding} fluid/>
</div>
</Slide>

<Slide right timeout={1000} >
<div className='col-12 col-md-6' >
          <div style={{marginTop:'20%'}} >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Commandez en toute sécurité :</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Ne payez qu’une fois la commande honorée. Vos billets et pièces ne sont attendus qu’à partir du moment où vos plats ou votre trajets sont arrivés à destination sans encombres.</p>
          </div>

        </div>
        </Slide>
      </div>


</div>



      <ArrowTop/>
      <div style={{marginTop:'10%'}} >
      <Footter/>
      </div>


      </div>

        );
    }
}

export default ClientPage;