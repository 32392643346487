import * as React from "react";
import {ImQuotesLeft} from 'react-icons/im';
import {ImQuotesRight} from 'react-icons/im';
import ImageAvatars from '../Avatar/Avatar';

class Citation extends React.Component {
  render() {
    return (
      <div style={{width:'40%'}} >
      <div className="card card-nav-tabs">
<div className="card-header card-header-success">
<ImageAvatars/>
</div>
<div className="card-body">
<blockquote className="blockquote mb-0">
<h6><ImQuotesLeft/>{' '} Mehdi kammoun zabel w akber kadhéb fi tounes kémla ken tmchilou 3ala cancer ya3tik zouz doliprane {' '}<ImQuotesRight/></h6>
<footer className="blockquote-footer"> <cite title="Source Title">Elyes ben hamadi CEO Yalla </cite></footer>
</blockquote>
</div>
</div>
      </div>
    );
  }
}

export default Citation ;
