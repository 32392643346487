import React,{useState} from 'react';
import { Jobs } from './CarrierPills/JobData';
import {Card,Button,Image} from 'react-bootstrap';
import Moment from 'react-moment' ;
import {AiOutlineCloudUpload} from 'react-icons/ai'; 
import {ImCancelCircle} from 'react-icons/im';
import {Link} from 'react-router-dom';

function JobPage  (props) {
    const [job,setJob] = useState(Jobs);
    console.log(Jobs.Id)
    return (
        <div >
           {
             job.map((el)=>el.Titre===props.match.params.id && (
             <div style={{marginTop:'2%'}} >
                   <div style={{display:'flex',justifyContent:'center'}} >
                <div  className="shadow p-3 mb-5 bg-white rounded card bg-light mb-3" style={{maxWidth: '50rem'}}>
                    <div style={{display:'flex',justifyContent:'space-between'}} className="card-header ">
                        <div>
                        <h3>{el.Titre}</h3>
                        </div>
                        <Link style={{textDecoration:'none'}} to='/carrier'>
                        <div style={{marginTop:'6px'}} >
                        <button type="button" className="close" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
                        </div>
                        </Link>
                      
                        
                        </div>
                    <div className="card-body">
                        <img style={{borderRadius:'5px'}} height={'350px'} width={'100%'} src={el.Image} />
                        <h6  style={{textAlign:'center',marginTop:'2%'}} className="card-title">Ajouter le{' '}
                          <Moment format='YYYY-MM-DD HH:mm'>
                          {el.Date}
                          </Moment>
                          </h6>
                        <p className="card-text">{el.Description}</p>
                    </div>
                    <Button style={{color:'white',backgroundColor:'#82BB25',borderRadius:'0px'}} > 
                    Postulez maintenant{' '}<AiOutlineCloudUpload/>
                    </Button>

                </div>
            </div>
             </div>))
           }

        </div>
    );
}
 
 
export default JobPage;