import React from 'react';
import 'leaflet/dist/leaflet.css';
import './LeafletPages.css';
import {
    TileLayer,
    Circle,
    MapContainer,
    Popup,
    CircleMarker,
    Polygon

} from 'react-leaflet';


function LeafletDjerba () {
    const center = [33.7735792,10.7536614]

    {/*











    */}

    const fillBlueOptions = { fillColor: 'blue' }
    const redOptions = { color: 'red' }
    const limeOptions = { color: 'lime' }
    return (


        <div className={'LeafletMap'} >
            <MapContainer center={center} zoom={10} scrollWheelZoom={false}>
                <TileLayer
                    style={{color:'black !important'}}
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polygon positions={[[33.9451, 10.89299],
                                     [33.92288, 10.71721],
                                     [33.75811, 10.70128],
                                     [33.67015, 10.76994],
                                     [33.57739, 10.99842],
                                     [33.44442, 11.03464],
                                     [33.44785, 11.14039],
                                     [33.54818, 11.15235],
                                     [33.68858, 11.02072],
                                     [33.81798, 11.10503]]} color="blue" />
                <Circle center={center} pathOptions={fillBlueOptions} radius={200} />

            </MapContainer>
        </div>

    );

}

export default LeafletDjerba;


