import {Parallax }from 'react-parallax';
import {Image} from 'react-bootstrap';
import Slide from 'react-reveal/Slide';
import React,{Component} from 'react';
import girl from '../Images/girl-4181395_1920.jpg';
import shopping from '../Images/Shopping 1.jpg';
import baked from '../Images/Baked.jpg';
import shelf from '../Images/shelf-1853439_1920.jpg';
import './RestaurantPages.css';

class CommercePage extends Component {
    render() {
        return (

            <div  >
                <Parallax blur={2} style={{width:'100%'}} bgImage={girl} >
                          <div className={'RestoParallax'} style={{height:'390px' , marginTop:'20%',marginLeft:'4%'}} >
                          <div className="container">
                              <div className={'DivTite'} style={{}}  >
                                 <h1 className={'RestaurantTitle'} style={{color:'white',textAlign:'center',
            fontFamily:'Poppins,sans-serif',fontWeight:'750',fontSize:'4.5em'}}>
                                 Augmentez votre rayon <br/> rayon d'action
                                 </h1>
                              </div>
        </div>
                          </div>
                      </Parallax>





                      <div style={{marginTop:'10%'}} className="container">

<div className='row' >
<Slide left timeout={1000}>
<div className='col-12 col-md-6' >

                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={shopping} fluid/>

                        </div>

                 </Slide>
                 <Slide right timeout={1000}>
        <div style={{marginTop:'8%'}}  className='col-12 col-md-6' >
          <div >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Profitez de votre notoriété :</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Combinez la force de vos magasins préparateurs et l’agilité de Yalla pour livrer aux clients leurs produits du quotidien de façon pratique.
</p>
          </div>

        </div>
        </Slide>

</div>

      </div>



      <div style={{marginTop:'10%'}}  className="container">



<div className='row flex-column-reverse flex-md-row  ' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6 ' style={{marginTop:'12%'}} >
 <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Touchez plus de public :</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Notre service n’est pas exclusif aux grandes surfaces. En effet, la clientèle peut être attachée à son commerce de proximité et vouloir continuer à le supporter, voir, le voisinage pourrait être curieux de découvrir les nouvelles saveurs aux alentours.</p>
</div>
</Slide>
<Slide right timeout={1000} >
<div className='col-12 col-md-6 ' >
                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={baked} fluid/>

</div>
</Slide>


</div>

     </div>





      <div style={{marginTop:'10%'}}  className="container">

<div className='row' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6' >
        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={shelf} fluid/>
</div>
</Slide>

<Slide right timeout={1000} >
<div className='col-12 col-md-6' >
          <div style={{marginTop:'20%'}} >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >La visibilité accrue pour les spécialistes:</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Les magasins de niches ou épiceries spécialisées peuvent aussi profiter de cette visibilité pour asseoir leur main mise sur le voisinage.</p>
          </div>

        </div>
        </Slide>
      </div>


</div>




      </div>

        );
    }
}

export default CommercePage;