import React from 'react';
import PropTypes from 'prop-types';
import {createMuiTheme,  ThemeProvider} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AppStore from './AppStore copy.png';
import GooglePlay from './GooglePlay copy.png';

const emails = ['username@gmail.com', 'user02@gmail.com'];


function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };



  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <div style={{display:'flex',justifyContent:'center'}} >
              <DialogTitle id="simple-dialog-title">Disponible sur:</DialogTitle>
      </div>
      <List>
          <ListItem button >

            <ListItemText primary={<img width={'100'} height={'50'} src={AppStore} />} />


          </ListItem>


      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function DialogSelect() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
   const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#82BB25',
    },

  },
});

  return (
    <div>

      <ThemeProvider theme={theme}>
      <Button variant="contained" color="primary" style={{color:'white',borderRadius:'1px',marginTop:'3%' }} onClick={handleClickOpen}>
        Télécharger l'application
      </Button>
      </ThemeProvider>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}