import React from 'react';
import 'leaflet/dist/leaflet.css';
import './LeafletPages.css';
import {
    TileLayer,
    Circle,
    MapContainer,
    Popup,
    CircleMarker,
    Polygon

} from 'react-leaflet';


function LeafletTouzeur () {
    const center = [33.9187468,8.084821]

    {/*








    */}

    const fillBlueOptions = { fillColor: 'blue' }
    const redOptions = { color: 'red' }
    const limeOptions = { color: 'lime' }
    return (


        <div className={'LeafletMap'} >
            <MapContainer center={center} zoom={10} scrollWheelZoom={false}>
                <TileLayer
                    style={{color:'black !important'}}
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polygon positions={[[33.84512, 7.82967],
                    [33.86355, 8.14972],
                    [34.00721, 8.31064],
                    [34.07574, 8.22084],
                    [33.98241, 8.10274],
                    [33.90156, 7.82623]



                ]} color="blue" />
                <Circle center={center} pathOptions={fillBlueOptions} radius={200} />

            </MapContainer>
        </div>

    );

}

export default LeafletTouzeur;


