import HomePage from './view/Home/Home';
import Chauffeur from './view/Chauffeur/Chauffeur';
//import traducted from "./Translation/traducted";
import Restaurant from './view/Restaurant/RestaurantsPages/RestaurantPage';
import {Route , BrowserRouter , Switch} from "react-router-dom";
import Inscription from './components/NavPills/PagesPills/Inscription';
import Support from "./view/Support/Support";
import LocationTunis from "./view/LocationPages/LocationTunis";
import LocationSousse from "./view/LocationPages/LocationSousse";
import LocationBizerte from "./view/LocationPages/LocationBizerte";
import LocationMonastir from "./view/LocationPages/LocationMonastir";
import LocationKairouan from "./view/LocationPages/LocationKairouan";
import LocationNabeul from "./view/LocationPages/LocationNabeul";
import LocationTouzeur from "./view/LocationPages/LocationTouzeur";
import LocationTabarka from "./view/LocationPages/LocationTabarka";
import LocationHammamet from "./view/LocationPages/LocationHammamet";
import LocationGafsa from "./view/LocationPages/LocationGafsa";
import LocationGabes from "./view/LocationPages/LocationGabes";
import LocationSfax from "./view/LocationPages/LocationSfax";
import LocationKelibia from "./view/LocationPages/LocationKelibia";
import LocationDjerba from "./view/LocationPages/LocationDjerba";
import Apropos from './view/Apropos/Apropos';
import Confidential from './view/Confidentiality/Confidential';
import Citation from './components/Citation/Citation';
import Navbar3 from './components/Navbar/Navbar3';
import InscritTaxi from './view/InscriptionPages/InscritTaxi';
import InscritPassager from './view/InscriptionPages/InscritPassager';
import InscritDelevry from './view/InscriptionPages/InscritDelevry';
import LoginDelevry from './view/LoginPages/LoginDelevry';
import LoginTaxi from './view/LoginPages/LoginTaxi';
import LoginPassager from './view/LoginPages/LoginPassager';
import ClientPage from "./view/Client/Client";
import Restaurants from "./view/Restaurant/Restaurants";
import EspacePresse from "./view/EspacePresse/EspacePresse";
import Fonctionnement from "./view/Fonctionnement/Fonctionnement";
import ServicePage from "./view/Services/ServicePage";
import DialogSelect from "./components/UiComponent/DialogSelect";
import WorkYalla from "./view/Carrière/CarrierPills/WorkYalla";
import Job from "./view/Carrière/CarrierPills/Job";
import MyJob from "./view/Carrière/CarrierPills/Job";
import Carriere from "./view/Carrière/Carriere";
import JobPage from './view/Carrière/JobPage';
import Notfoundpage from './view/404notfound';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Switch>
          {/* home page */}
      <Route exact path={'/'} component={HomePage}/>
       <Route exact path={'/DialogSelect'} component={DialogSelect}/>




                        {/* Espace presse */}
          <Route exact path={'/Espace_presse'} component={EspacePresse} />



          {/* fonctionnement */}
          <Route exact path={'/Fonctionnement'} component={Fonctionnement} />


          {/* service */}
             <Route exact path={'/Service'} component={ServicePage} />

              {/* page carrière */}
          <Route exact path={'/Carrier'} component={Carriere} />
          <Route exact path={'/JobPage/:id'} component={JobPage} />

          {/* inscription pages */}
      <Route exact path={'/InscritTaxi'} component={InscritTaxi} />
      <Route exact path={'/InscritPassager'} component={InscritPassager} />
      <Route exact path={'/InscritDelevry'} component={InscritDelevry} />

{/* Login Pages */}
<Route exact path={'/LoginTaxi'} component={LoginTaxi} />
<Route exact path={'/LoginPassager'} component={LoginPassager} />
<Route exact path={'/LoginDelevry'} component={LoginDelevry} />



      {/* Restaurant pages*/ }
      <Route exact path={'/Restaurant'} component={Restaurants} />

{/* Client Page */}
                <Route exact path={'/ClientPage'} component={ClientPage} />
          {/* Chauffeur page */}
      <Route exact path={'/Chauffeur'} component={Chauffeur}/>
      {/* Citationt page*/ }
      <Route exact path={'/Citation'} component={Citation}/>
          {/* Support Page */}
          <Route exact path={'/Support'} component={Support}/>
          {/*Confidentialite Page*/}
          <Route exact path={'/Confidentiality'} component={Confidential} />
          {/* Location Pages */}
          <Route exact path={'/LocationTunis'} component={LocationTunis}/>
          <Route exact path={'/LocationSousse'} component={LocationSousse}/>
          <Route exact path={'/LocationBizerte'} component={LocationBizerte}/>
          <Route exact path={'/LocationMonastir'} component={LocationMonastir}/>
          <Route exact path={'/LocationKairouan'} component={LocationKairouan}/>
          <Route exact path={'/LocationNabeul'} component={LocationNabeul}/>
          <Route exact path={'/LocationTouzeur'} component={LocationTouzeur}/>
          <Route exact path={'/LocationTabarka'} component={LocationTabarka}/>
          <Route exact path={'/LocationHammamet'} component={LocationHammamet}/>
          <Route exact path={'/LocationGafsa'} component={LocationGafsa}/>
          <Route exact path={'/LocationGabes'} component={LocationGabes}/>
          <Route exact path={'/LocationSfax'} component={LocationSfax}/>
          <Route exact path={'/LocationKelibia'} component={LocationKelibia}/>
          <Route exact path={'/LocationDjerba'} component={LocationDjerba}/>
          {/* Nav Pills Links */}
      <Route exact path={'/inscription'} component={Inscription} />
      <Route exact path={'/Apropos'} component={Apropos} />
      <Route exact path={'*'} component={Notfoundpage} />
          {/* Side Nav Links */}
  

      </Switch>


      </BrowserRouter>

 {/*


      <Route path={'/SideNav/Avantages'} component={Avantages} />
     <Route path={'/NavPills2/Social'} component={Social} />
     <Route path={'/NavPills2/Prise'} component={Prise} />
     
      */}
    </div>
  );
}

export default App;
