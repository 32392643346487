import React, { Component } from 'react';
import Protest from '../images/Protest.jpg';
import Banane from '../images/Banane.jpg';
import {Image} from 'react-bootstrap';
import '../SecondNav/SecondNav.css';
import Slide from 'react-reveal/Slide';
import './PagesPills.css';
import Button from '@material-ui/core/Button';
import { createMuiTheme,ThemeProvider } from '@material-ui/core/styles';
 
class Social extends Component {
    render() {

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#82BB25',
    },

  },
});
        return (
            <div className={'plafond'} style={{marginTop:'6%'}}>
              <div>
    <h1 style={{textAlign:'center'}} >Les droits humains et les revendications, socle de l’innovation durable
</h1>
    <p style={{textAlign:'center'}} >
    e sujet de la précarité et des conditions de travail sont importants aux yeux de Yalla,
c’est pourquoi la plateforme se veut le plus juste possible et prends en considération l’avis de tous ses collaborateurs sans exception</p>
</div>
            <div style={{marginTop:'10%'}} className="container">

<div className='row' >
<Slide left timeout={1000}>
<div className='col-12 col-md-6' >

                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={Protest} fluid/>


                        </div>
                           
                 </Slide>
                 <Slide right timeout={1000}>
        <div style={{marginTop:'10%'}}  className='col-12 col-md-6 ColParag2 ' >
          <div >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Une entreprise sociale</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Toutes les formes de revendication réaliste et de syndicalisation est encouragée.
Dans ce sens, chaque année, chaque partenaire pourra voter pour un collègue qui deviendra le porte parole officiel au sein de Yalla. </p>
          </div>
          <div style={{display:'flex',justifyContent:'center'}} >
 <ThemeProvider theme={theme}>
        <Button variant="contained" color="primary"  style={{color:'white',borderRadius:'1px' }} className='btn' >Représentants officielles</Button>
                              </ThemeProvider>
          </div>
        </div>
        </Slide>
           
</div>
             
      </div>
                      
    

      <div style={{marginTop:'10%'}}  className="container">



<div className='row flex-column-reverse flex-md-row  ' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6 ColParag2 ' style={{marginTop:'10%'}} >
 <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Un accompagnement tout au long de l’activité</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Si vous êtes un utilisateur récurrent de Yalla,
l’entreprise vous supportera, dans certaines limites et conditions, dans les tracas et événements de la vie.</p>
<div style={{display:'flex',justifyContent:'center'}} >
 <ThemeProvider theme={theme}>
        <Button variant="contained" color="primary"  style={{color:'white',borderRadius:'1px' }} className='btn' >Assurances Partenaires</Button>
                              </ThemeProvider>
</div>
</div>

</Slide>
<Slide right timeout={1000} >
<div className='col-12 col-md-6 ' >
                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={Banane} fluid/>

</div>
</Slide>


</div>
      
     </div>
            </div>
        );
    }
}
 
export default Social;