import {Parallax }from 'react-parallax';
import NavBar2 from './../../components/Navbar/Navbar2';
import hiphop from './Images/Hiphop.jpg';
import {Image} from 'react-bootstrap';
import Elyes from './Images/Elyes.jpg';
import Man from './Images/man-1031385_1920.jpg';
import Nature from './Images/agriculture-1807581_1920.jpg';
import Technologie from './Images/blur-1853262_1920.jpg';
import bhar from './Images/landscape-5407355_1920.jpg';
import Team from './Images/workplace-1245776_1920.jpg';
import Footter from '../../components/Footer/Footter';
import ArrowTop from '../../components/ArrowTop/ArrowTop';
import Slide from 'react-reveal/Slide';
import React,{Component} from 'react';
import './Apropos.css';
import TeamSlide from "../../components/TeamSlide/TeamSlide";


class Apropos extends Component {
    render() { 
        return (
            
            <div  >
                <NavBar2/>
                <Parallax blur={2} style={{width:'100%'}} bgImage={hiphop} >
                    
                          <div className={'MyParallax'} style={{height:'390px' , marginTop:'20%',marginLeft:'4%'}} >
                          <div className="container">
                              <div className={'DivTitle'} >
                                 <h1 className={'AproposTitle'} style={{color:'#F8F9FA',textAlign:'center',
            fontFamily:'Poppins,sans-serif',fontWeight:'750',fontSize:'4.5em'}}>
                                 À Propos de Yalla ! <br/> qui sommes-nous ?
                                 </h1>
                             
                              </div>
         
        </div>
                          </div>
                      </Parallax>
               
                      <div style={{marginTop:'2%',Color:'#F8F9FA',width:'95%',marginRight:'2.5%',marginLeft:'2.5%'}} >
                         <h4 style={{fontWeight:'bold',fontFamily:'Lato, sans-serif'}} >À Propos :</h4>
<h4 style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >
Après avoir subit plusieurs tragédies au cours de son histoire, de multiples guerres, conflits ou révolutions et enfin une<br/> pandémie mondiale durant notre ère moderne, il nous paraît primordial de relever le niveau de nos sociétés actuelles.
Saisir les opportunités et transformer les rêves en réalité pour des millions de personnes ainsi que des professionnels est une de nos priorités.
En effet, ce qui n’était que de simples services de mise en relation sont devenus des mastodontes profitant de leur pouvoir pour asseoir leurs conditions et tiennent donc par le col tous leurs utilisateurs.
Zota Technologies se voit en Robin des Bois des temps modernes tout en jouant au jeu (levées de fond, profits, politique, etc...) de ces entreprises en essayant d’éviter tous les aspects négatifs qui en découlerait car l’innovation ne doit pas être un synonyme de précarité.

</h4>
                      </div>
        
        <div style={{marginTop:'4%'}} className={'CEO'} >
        <Image style={{width:'50%',marginLeft:'25%',marginRight:'25%'}} src={Elyes} fluid />
        </div>
        <div style={{marginTop:'3%',Color:'#F8F9FA',width:'95%',marginRight:'2.5%',marginLeft:'2.5%'}} >
                         <h4 style={{fontWeight:'bold',fontFamily:'Lato, sans-serif'}} >Témoignage de Elyes ben hamadi "CEO Yalla" :</h4>
<h4 style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >
Après avoir subit plusieurs tragédies au cours de son histoire, de multiples guerres, conflits ou révolutions et enfin une<br/> pandémie mondiale durant notre ère moderne, il nous paraît primordial de relever le niveau de nos sociétés actuelles.
Saisir les opportunités et transformer les rêves en réalité pour des millions de personnes ainsi que des professionnels est une de nos priorités.
En effet, ce qui n’était que de simples services de mise en relation sont devenus des mastodontes profitant de leur pouvoir pour asseoir leurs conditions et tiennent donc par le col tous leurs utilisateurs.
Zota Technologies se voit en Robin des Bois des temps modernes tout en jouant au jeu (levées de fond, profits, politique, etc...) de ces entreprises en essayant d’éviter tous les aspects négatifs qui en découlerait car l’innovation ne doit pas être un synonyme de précarité.

</h4>
                      </div>
                      
                      <div style={{marginTop:'10%'}} className="container">

<div className='row' >
<Slide left timeout={1000}>
<div className='col-12 col-md-6' >

                        <Image style={{borderRadius:'5px'}}  src={Man} fluid/>


                        </div>
                           
                 </Slide>
                 <Slide right timeout={1000}>
        <div style={{marginTop:'5%'}}  className='col-12 col-md-6' >
          <div >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >L’Humain au coeur de notre développement</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Préparer l’avenir des technologies ne se fait pas au dépend des gens qui participent à ces évolutions. Nous prenons soin de nos partenaires en prenant tout ce que les avancées sociétales a fait de mieux dans notre histoire dans les principes de notre entreprise. Nous faisons en sorte de créer le maximum de solutions, existantes ou non, avec une visée de profit (ce serait vous mentir que de vous dire le contraire) mais en ne perdant pas de vue le principe d’équité. C’est pour cela qu’il nous paraît important de prendre en compte autant l’aspect des consommateurs que des professionnels dans nos développement.</p>
          </div>
          
        </div>
        </Slide>
           
</div>
             
      </div>
                      
    

      <div style={{marginTop:'10%'}}  className="container">



<div className='row flex-column-reverse flex-md-row  ' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6 ' style={{marginTop:'4%'}} >
 <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Respecter notre belle planète</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Nous ne promettons de devenir une plateforme totalement électrique d’ici 2040 comme certains de nos concurrents mais nous promettons de faire de notre mieux. En effet, il nous paraît excessif d’être dans optique restrictive voire punitive, surtout pour les marchés peu développés ou en développement à l’heure actuelle qui ont malheureusement d’autres priorités à mettre en avant afin d’améliorer leur condition de vie. C’est pour cela que nous sommes plus dans une optique d’accompagnement où nous préférons « encourager » nos partenaires d’aller dans le bon sens en leur donnant accès à certains avantages.</p>
</div>
</Slide>
<Slide right timeout={1000} >
<div className='col-12 col-md-6 ' >
                        <Image style={{borderRadius:'5px'}}  src={Nature} fluid/>

</div>
</Slide>


</div>
      
     </div>





      <div style={{marginTop:'10%'}}  className="container">

<div className='row' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6' >
        <Image style={{borderRadius:'5px'}}  src={Technologie} fluid/>
</div>
</Slide>

<Slide right timeout={1000} >
<div className='col-12 col-md-6' >
          <div style={{marginTop:'7%'}} >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >La transparence au service des engagements</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >La transparence fait partie de nos priorités. Nous profitons de cette occasion pour expliquer que nous sommes enclin à partager un maximum d’informations avec nos partenaires ou avec des ONG dans le but d’améliorer soit notre service, dans le cas où des failles sont trouvées, ou même de proposer de nouvelles solutions si cela permet de participer à l’évolution sociétale nécessaire telle que la transition écologique ou la place de la femme dans le milieu du travail.</p>
          </div>
         
        </div>
        </Slide>
      </div>

 
</div>

       
      <div style={{marginTop:'10%'}}  className="container">

<div className='row flex-column-reverse flex-md-row  ' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6 ' style={{marginTop:'6%'}} >
 <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Votre sécurité, notre priorité</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >La sécurité, que ce soit pour nous ou pour nos partenaires, est d’une importance primordiale. La technologie doit entre autre avancer afin d’améliorer le niveau de cette dernière. C’est pour cela que nous collaborons au mieux avec les services de l’ordre (quand nous estimons que cela va dans le sens d’aider les consommateurs) ainsi que des spécialistes et des défenseurs de la sécurité dans l’idée de développer et renforcer la sérénité de nos consommateurs.</p>
</div>
</Slide>

<Slide right timeout={1000} >
<div className='col-12 col-md-6 ' >
                        <Image style={{borderRadius:'5px'}}  src={bhar} fluid/>

</div>
</Slide>




</div>
      
     </div>




    <div style={{marginTop:'10%'}}  className="container">

<div className='row' >
<Slide left timeout={1000} >

<div className='col-12 col-md-6' >
        <Image style={{borderRadius:'5px'}}  src={Team} fluid/>
</div>
</Slide>
<Slide right timeout={1000} >

 <div className='col-12 col-md-6' >
          <div style={{marginTop:'10%'}} >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Diversité et Intégration</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >La société ne doit pas évoluer que dans le sens de l’écologie, qui prévoit une vie convenable pour les générations futures, mais doit aussi évoluer dans le sens de donner une chance égalitaire à tout le monde, quelque soit le genre ou les origines. Créer un espace de travail authentique où personne ne se sent exclu est une de nos valeurs et nous voyons cela comme une force nous permettant de favoriser l’épanouissement ainsi que la prise d’initiative pour l’innovation, car ils font tout autant partie de nos services que nos partenaires.</p>
          </div>
         
        </div>
        </Slide>
      </div>

</div>

      <div style={{marginTop:'6%'}} >
<TeamSlide/>
      </div>
<ArrowTop/>
      <div  >
      <Footter/>
      </div>
      

      </div>
      
        );
    }
}
 
export default Apropos; 