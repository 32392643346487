import React, {Component} from 'react';
import {IoMdMail} from 'react-icons/io';
import {FaPhoneAlt} from 'react-icons/fa';
import {MdGpsFixed} from 'react-icons/md'
import facebook from './icons/facebook.svg';
import youtube from './icons/youtube.svg';
import instagram from './icons/instagram.svg';
import twitter from './icons/twitter.svg';
import linkedin from './icons/linkedin.svg';
import Yalla from './icons/Yalla.svg';
import './Footter.css';
import $ from "jquery";
import GooglePlay from './icons/Google_Play_Store_badge_FR.svg';
import AppStore from './icons/Download_on_the_App_Store_Badge (2).svg';



class Footter extends Component {

     componentDidMount() {
        $(function(){
            $('.LinkFooter').mouseover(function(){
                $('.LinkFooter').css('opacity','0.8');
                $(this).css('opacity','2')
            })
            $('.LinkFooter').css('color','white');
            $('.LinkFooter').css('text-decoration','none');
            $('h6').css('color','white')
        })
    }

   

    render() {
        return (
            
            <div >

                {/* Footer */}
                <footer style={{backgroundColor:'#82bb25'}} className="page-footer  font-small indigo">
                    {/* Footer Links */}
                    <div className="container text-center text-md-left">
                        {/* Grid row */}
                        <div className="row">
                            {/* Grid column */}
                            <div className="col-md-3 mx-auto">
                                {/* Links */}
                                <h5 id='five' className="font-weight-bold text-uppercase mt-3 mb-4"><img margin='50px' height='40px' src={Yalla}  /></h5>
                                <h6>Contactez nous :</h6>
                                <ul className="list-unstyled">
                                   
                                    <li>
                                        <a className='LinkFooter' href="#!"><IoMdMail/> Yalla@gmail.com</a>
                                    </li>
                                    <li>
                                        <a className='LinkFooter' href="#!"><FaPhoneAlt/> +21655781761</a>
                                    </li>
                                    <li>
                                        <a className='LinkFooter' href="#!"><MdGpsFixed/> Cite Mehrajene</a>
                                    </li>
                                </ul>
                            </div>
                            {/* Grid column */}
                            <hr className="clearfix w-100 d-md-none" />
                            {/* Grid column */}
                            <div className="col-md-3 mx-auto">
                                {/* Links */}
                                <h5 id='five' className="font-weight-bold text-uppercase mt-3 mb-4">Services</h5>
                                <ul className="list-unstyled">
                                    <li>
                                        <a className='LinkFooter' href="#!">Chauffeurs</a>
                                    </li>
                                    <li>
                                        <a className='LinkFooter' href="#!">Commandes</a>
                                    </li>
                                    <li>
                                        <a className='LinkFooter' href="#!">Shopping</a>
                                    </li>
                                    <li>
                                        <a className='LinkFooter' href="#!">Résérvation</a>
                                    </li>

                                </ul>
                            </div>
                            {/* Grid column */}
                            <hr className="clearfix w-100 d-md-none" />
                            {/* Grid column */}
                            <div className="col-md-3 mx-auto">
                                {/* Links */}
                                <h5 id='five' className="font-weight-bold text-uppercase mt-3 mb-4">Entreprise</h5>
                                <ul className="list-unstyled">
                                    <li>
                                        <a className='LinkFooter' href="#!">A propos de nous</a>
                                    </li>
                                    <li>
                                        <a className='LinkFooter' href="#!">Nos Services</a>
                                    </li>
                                    <li>
                                        <a className='LinkFooter' href="#!">Espace Presse</a>
                                    </li>
                                    <li>
                                        <a className='LinkFooter'  href="#!">Carrière</a>
                                    </li>
                                    <li>
                                        <a className='LinkFooter' href="#!">Support</a>
                                    </li>

                                </ul>
                            </div>
                            {/* Grid column */}
                            <hr className="clearfix w-100 d-md-none" />
                            {/* Grid column */}
                            <div className="col-md-3 mx-auto">
                                {/* Links */}
                                <h5 id='five' className="font-weight-bold text-uppercase mt-3 mb-4">Nos convictions</h5>
                                <ul className="list-unstyled">
                                    <li>
                                        <a className='LinkFooter' href="#!">Sécurité</a>
                                    </li>
                                    <li>
                                        <a className='LinkFooter'  href="#!">Innovation</a>
                                    </li>
                                    <li>
                                        <a className='LinkFooter' href="#!">Satisfaction</a>
                                    </li>

                                </ul>
                                
                            </div>
                            
                            {/* Grid column */}
                        </div> &nbsp;&nbsp;
                        <div style={{display:'flex',justifyContent:'center', marginTop:'10px'}} ><h5 id='five'>Suivez nous sur :</h5></div>&nbsp;&nbsp;
                        <div style={{display:'flex',justifyContent:'space-between',marginTop:'10px',marginBottom:'10px'}} >
                                    <div><a className='LinkFooter' href="#!"><img height='50' width='50' src={twitter} /></a></div>
                                    <div><a className='LinkFooter' href="#!"><img height='50' width='50' src={instagram}/></a></div>
                                    <div><a className='LinkFooter' href="#!"><img height='50' width='50' src={facebook} /></a></div>
                                    <div><a className='LinkFooter' href="#!"><img height='50' width='50' src={linkedin} /></a></div>
                                    <div><a className='LinkFooter' href="#!"><img height='50' width='50' src={youtube} /></a></div>
                                </div>
                        {/* Grid row */}
                    </div>
                    <div style={{display:"flex",justifyContent:'center'}}>
                      <b>  <p style={{color:'white'}}>Disponible sur:</p></b>
                    </div>
                    <div style={{display:"flex",justifyContent:'center'}} >
                                          <div>
                                          <a style={{opacity:'5 !important'}} href='https://play.google.com/' >
    <img width="150" height="40" style={{borderRadius:'7px'}} src={GooglePlay}/>
    </a>
                                              </div>
                                              &nbsp;&nbsp;
                                              <a style={{opacity:'5 !important'}} href='https://play.google.com/' >
    <img width="150" height="40" style={{borderRadius:'7px'}} src={AppStore}/>
    </a>
                                              </div>
                    {/* Footer Links */}
                    {/* Copyright */}
                   
                    {/* Copyright */}
                    <br/>

                      <div className="container">
          <div className="row">
            <div className="col">
              <ul className="list-inline text-center">
                <li className="list-inline-item"><a className='LinkFooter' href="#!" >Confidentialité</a></li>
                <li className="list-inline-item">·</li>
                <li className="list-inline-item"><a className='LinkFooter' href="#!" >Accessibilité</a></li>
                <li className="list-inline-item">·</li>
                <li className="list-inline-item"><h6 >©Zota services Inc</h6></li>
                   <li className="list-inline-item">·</li>
                <li className="list-inline-item"><a className='LinkFooter' href="#!" >Mentions légale</a></li>
                   <li className="list-inline-item">·</li>
                <li className="list-inline-item"><a className='LinkFooter' href="#!" >Conditions</a></li>
              </ul>
            </div>
          </div>
        </div>
                </footer>
                {/* Footer */}

            </div>
        );
    }
}

export default Footter;