import {Parallax }from 'react-parallax';
import {Image} from 'react-bootstrap';
import Slide from 'react-reveal/Slide';
import React,{Component} from 'react';
import bar from '../Images/bar-1846137_1920.jpg';
import restaurant from '../Images/restaurant-690975_1920.jpg';
import food from '../Images/food-712665_1920.jpg';
import people from '../Images/people-2590685_1920.jpg';
import './RestaurantPages.css';


class ReservationPage extends Component {
    render() {
        return (

            <div  >
                <Parallax blur={2} style={{width:'100%'}} bgImage={bar} >
                          <div className={'RestoParallax'} style={{height:'390px' , marginTop:'20%',marginLeft:'4%'}} >
                          <div className="container">
                              <div className={'DivTite'} style={{}}  >
                                 <h1 className={'RestaurantTitle'} style={{color:'white',textAlign:'center',
            fontFamily:'Poppins,sans-serif',fontWeight:'750',fontSize:'4.5em'}}>
                                 Donnez un coup de pousse<br/> à votre restaurant
                                 </h1>
                              </div>
        </div>
                          </div>
                      </Parallax>






                      <div style={{marginTop:'10%'}} className="container">

<div className='row' >
<Slide left timeout={1000}>
<div className='col-12 col-md-6' >

                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={restaurant} fluid/>

                        </div>

                 </Slide>
                 <Slide right timeout={1000}>
        <div style={{marginTop:'8%'}}  className='col-12 col-md-6' >
          <div >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Attirez de nouveaux clients :</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Vous augmentez votre visibilité tout en gardant le contrôle de vos réservations et de la présentation de votre établissement. C’est l’occasion de toucher de nombreux nouveaux clients et d’augmenter vos volumes de réservations.</p>
          </div>

        </div>
        </Slide>

</div>

      </div>



      <div style={{marginTop:'10%'}}  className="container">



<div className='row flex-column-reverse flex-md-row  ' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6 ' style={{marginTop:'12%'}} >
 <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >La carte des bons plans :</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Bien plus qu’un service de réservation, Yalla est aujourd’hui un véritable guide gastronomique urbain et communautaire.</p>
</div>
</Slide>
<Slide right timeout={1000} >
<div className='col-12 col-md-6 ' >
                        <Image className='shadow p-3 mb-5 bg-white rounded' style={{borderRadius:'5px'}}  src={food} fluid/>

</div>
</Slide>


</div>

     </div>





      <div style={{marginTop:'10%'}}  className="container">

<div className='row' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6' >
        <Image style={{borderRadius:'5px'}}  src={people} fluid/>
</div>
</Slide>

<Slide right timeout={1000} >
<div className='col-12 col-md-6' >
          <div style={{marginTop:'20%'}} >
          <h2 style={{textAlign:'left',fontFamily:'Lato, sans-serif'}} >Gérer au mieux vos réservations :</h2>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif'}} >Vous pouvez centraliser vos réservations et de suivre votre service en temps réel, à tout moment et sur différents supports numériques. Une aide précieuse pour optimiser la rotation de vos tables et votre taux d’occupation à chaque service.</p>
          </div>

        </div>
        </Slide>
      </div>


</div>






      </div>

        );
    }
}

export default ReservationPage;