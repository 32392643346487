import React, {Component} from 'react';
import {Navbar,Nav,Form} from 'react-bootstrap';
import Yalla from './icons/YallaLogo.png';
import './NavBar.css';
import $ from 'jquery';
import {BiHelpCircle} from 'react-icons/bi';
import {AiOutlineHome} from 'react-icons/ai';
import {AiOutlineLogin} from 'react-icons/ai';
import {SiGnuprivacyguard} from "react-icons/all";
class Navbar3 extends Component {

    //jequry effects
    componentDidMount() {


        // scroll navbar effect
        $(window).on('scroll',function () {
            if($(window).scrollTop()){
                $('#ThirdNav').addClass('bg-warning')
            }
            else{
                $('#ThirdNav').removeClass('bg-warning')
            }
        });
    };

    render() {

        return (
            <div>
                <Navbar className='fixed-top' id={'ThirdNav'} style={{background:'transparent'}} expand="lg">
                    <Navbar.Brand href="#home"><img src={Yalla} height='40' width='80' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">


                        </Nav>
                        <Form inline>
                            <Nav.Link style={{color:'black'}}>Aidez moi <BiHelpCircle/></Nav.Link>
                            <Nav.Link style={{color:'black'}}>Home <AiOutlineHome/></Nav.Link>
                            <Nav.Link style={{color:'black'}} > S'inscrire  <AiOutlineLogin/> </Nav.Link>
                            <Nav.Link style={{color:'black'}}>Connectez-vous <SiGnuprivacyguard/> </Nav.Link>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

export default Navbar3;