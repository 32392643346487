import React, {Component} from 'react';
import Slide from "react-reveal/Slide";
import {Image} from "react-bootstrap";
import serpentin from '../Images/Serpentin reservation.png';

class Reservation extends Component {
    render() {
        return (
            <div style={{marginTop:'4%'}}>

      <div  className="container-fluid">

<div className='row' >
<Slide left timeout={1000} >
<div className='col-12 col-md-6' >
        <Image style={{marginLeft:'17%'}}  width={'70%'} src={serpentin}  fluid/>
</div>
</Slide>

<div className='col-12 col-md-6' >
          <div style={{marginTop:'5%'}}  >
          <h3 style={{textAlign:'left',fontFamily:'Lato, sans-serif',fontWeight:'bold',color:'#82BB25'}} >1.Le client se connecte sur son application</h3>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif',fontWeight:'bold'}} >Le client se connecte sur son compte personnel afin d'accéder aux différents services proposés par Yalla.</p>
              <h3 style={{textAlign:'left',fontFamily:'Lato, sans-serif',fontWeight:'bold',color:'#82BB25'}} >2.Le client choisit son restaurant</h3>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif',fontWeight:'bold'}} >Il se verra proposer différents restaurants en fonction de ses filtres et l'emplacement choisi, et pourra donc observer leur menus avec les prix associés.</p>
              <h3 style={{textAlign:'left',fontFamily:'Lato, sans-serif',fontWeight:'bold',color:'#82BB25'}} >3.Choix d'une promotion</h3>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif',fontWeight:'bold'}} >La plupart du temps, les restaurants que vous trouveriez à travers nos listings proposeront de nombreuses promotions à conditions dont vous pourriez profitez.</p>
              <h3 style={{textAlign:'left',fontFamily:'Lato, sans-serif',fontWeight:'bold',color:'#82BB25'}} >4.Validation de la réservation</h3>
          <p style={{textAlign:'justify',fontFamily:'Lato, sans-serif',fontWeight:'bold'}} >Une notification sera envoyée au client lorsque le restaurant l'aura bien validée. Ce dernier n'aura plus qu'à se présenter avec ses convives au moment donné. </p>
          </div>

        </div>
      </div>


</div>
            </div>
        );
    }
}

export default Reservation;